import React, { Component } from "react";
import axios from "axios";
import { globalkey, globaladdress } from '../../page/variables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class NewInvitationLayerNofields extends Component {
  state = {
    templateid: '',
    newtext_length: null,
    newtext_width: null,
    // isbold: false,
    label: '',
    value: '',
    e_layer_xpos_1: '',
    e_layer_ypos_1: '',
    e_layer_size_1: '',
    e_isBold_1: '',
    e_layer_algn_1: '',
    e_layer_color_1: '',
    e_layer_length_1: '',
    e_layer_width_1: '',
    e_label_1: '',
    e_value_1: ''
  }
  constructor(props) {

    super(props);
    // this.changeboldStatus = this.changeboldStatus.bind(this);
    this.showNewLayer = this.showNewLayer.bind(this);
    this.inputcheck = this.inputcheck.bind(this);
    this.submitLayer = this.submitLayer.bind(this);

  }
  componentDidMount() {
    this.setState({ label: this.props.flabel, value: this.props.labelval, templateid: this.props.tid })
  }
  showNewLayer() {
    document.getElementById("new_layer").classList.add(['show_new']);
  }
  // changeboldStatus() {
  //   var checkbox = document.getElementById("isBold");
  //   this.setState({ isbold: checkbox.checked })

  // }

  inputcheck(e) {

    var el = e.currentTarget;

    var e_layer_xpos_1 = document.getElementById('e_layer_xpos').value
    var e_layer_ypos_1 = document.getElementById('e_layer_ypos').value
    var e_layer_size_1 = document.getElementById('e_layer_size').value
    var e_layer_algn_1 = document.getElementById('e_layer_algn').value
    var e_layer_color_1 = document.getElementById('e_layer_color').value
    var e_layer_length_1 = document.getElementById('e_layer_length').value
    var e_layer_width_1 = document.getElementById('e_layer_width').value
    var e_isBold_1 = "false";

    // alert(document.getElementById('e_layer_length').value);
    var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
    if (el.value !== '' && !el.classList.contains('hastext')) {
      el.classList.add('hastext')
      if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    } else if (el.value === '' && el.classList.contains('hastext')) {
      el.classList.remove('hastext')
      alertmsg.add('invalid')
    }
    this.setState({
      e_layer_xpos_1: e_layer_xpos_1,
      e_layer_ypos_1: e_layer_ypos_1,
      e_layer_size_1: e_layer_size_1,
      e_layer_algn_1: e_layer_algn_1,
      e_layer_color_1: e_layer_color_1,
      e_layer_length_1: e_layer_length_1,
      e_layer_width_1: e_layer_width_1,
      e_isBold_1: e_isBold_1
    });
  }
  submitLayer(e) {

    // alert("e_layer_xpos_1" + e_layer_xpos_1);



    var l_1, l_2, l_3, l_4, l_5, l_6, l_7, l_8, l_9, l_10, formdata;
    l_1 = this.state.label;
    l_2 = this.state.value;
    l_3 = this.state.e_layer_xpos_1;
    l_4 = this.state.e_layer_ypos_1;
    l_5 = this.state.e_layer_size_1;
    l_6 = this.state.e_isBold_1;
    l_7 = this.state.e_layer_algn_1;
    l_8 = this.state.e_layer_color_1;
    l_9 = this.state.e_layer_length_1;
    l_10 = this.state.e_layer_width_1;
    toast.info("label: " + l_1);
    toast.info("value: " + l_2);
    toast.info("xpos: " + l_3);
    toast.info("ypos: " + l_4);
    toast.info("size: " + l_5);
    toast.info("bold: " + l_6);
    toast.info("alignment: " + l_7);
    toast.info("color: " + l_8);
    toast.info("text_length: " + l_9);
    toast.info("layer_width: " + l_10);


    if (l_1 && l_2 && l_3 && l_4 && l_5 && l_7 && l_8 && l_9 && l_10) {
      toast.info("if condition");
      formdata = new FormData();
      formdata.append("label", l_1);
      formdata.append("value", l_2);
      formdata.append("xpos", l_3);
      formdata.append("ypos", l_4);
      formdata.append("size", l_5);
      formdata.append("bold", l_6);
      formdata.append("alignment", l_7);
      formdata.append("color", l_8);
      formdata.append("text_length", l_9);
      formdata.append("layer_width", l_10);
      formdata.append("templateid", this.state.templateid);
      toast.info("JSON.stringify(formdata)");

      toast.info(JSON.stringify(formdata));
      axios.post(globaladdress + 'addtemplatefields?key=' + globalkey, formdata)
        .then(res => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            const res = JSON.parse(resText);
            window.location.reload();
          }
        })
        .catch(error => {
          console.log("Axios error: ", error);
        });

    } else {
      toast.warning("required fields cannot be empty!")
    }
  }
  closeLayer() {
    document.getElementById("new_layer2").classList.remove(['show_new']);
  }
  render() {
    return <div id="new_layer2" className="add_layer">
      <button type="button" className="close-layer" aria-label="Close" onClick={this.closeLayer}><i className="fa fa-times"></i></button>
      <h3 className="no-margin">New Layer ({this.state.label})</h3>
      <hr className="line_1" />
      <div className="fields">

        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_length" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Text length *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_width" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Text width *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_xpos" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">X-pos *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_ypos" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Y-pos *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_size" className="special-input hastext" type="number" defaultValue={20} onChange={this.inputcheck} />
            <label className="special-lable">Size *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>


        {/* <div className="col-md-6">
          <div className="form-field">
            <div className="layer_label_content">Bold :</div>
            <div className="toggle-switch">

              <input type="checkbox"
                className="checkbox"
                name='isBold'
                id='isBold'
                onChange={this.changeboldStatus}
                checked={this.state.isbold} />

              <label className="label" htmlFor="isBold">
                <span className="inner" />
                <span className="switch" />
              </label>
            </div>

          </div>
        </div> */}
        <div className="col-md-5">
          <div className="form-field">
            <select id="e_layer_algn" className="special-input hastext" onChange={this.inputcheck}>
              <option value={1}>Left</option>
              <option value={2}>Center</option>
              <option value={3}>Right</option>

            </select>
            <label className="special-lable">Alignment *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-field">
            <input id="e_layer_color" type="color" defaultValue={'#000000'} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Color *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>

        <button className="btn-primary layer-btn floar-right-btn" onClick={this.submitLayer}><i className="fa fa-plus"></i> Add Layer</button>

      </div>
    </div>
  }
}
export default NewInvitationLayerNofields;
