
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from "../components/Navbar";
import { globaladdress, globalkey, s_key, s_secret } from './variables';
import Header from '../components/Header/header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import signup from './assets/5.png';
// import '../smscholarly.css';
// import { useFormik } from 'formik'
// import avatar from'./avatar.png'
// import SandMLogo from './assets/5.png';
function SignUp() {
  const [fileupload, setFile] = useState('null');
  const readImage = (inp) => {
    var file = inp.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById('img_preview')
          .setAttribute('src', e.target.result)

      };
      reader.readAsDataURL(file);
    }
    setFile(file);

  }
  // const isValidEmail=(email)=> {
  //   return /\S+@\S+\.\S+/.test(email);
  // }
  const registerUser = () => {
    // var isValidEmail=/\S+@\S+\.\S/.test("email");


    try {
      var formdata = new FormData();

      formdata.append('avatar', fileupload);

      axios.post(globaladdress + 'addimage' + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else if (resText === 'username already taken') {
            toast.info("user credentials already taken");
            toast.info("redirecting to login page");
          }
          if (resText === "insert succeded.") {
            toast.success("hurrey!!!")
            window.location.replace('./login');
          }
          if (resText === "user credentials already taken") {
            window.location.replace('./login');
          }

        })
    } catch {
      toast.error("unable to call the api");
    }



  }

  // const validate = (values) => {
  //   const errors = {}

  //   if (!values.email) {
  //     errors.email = 'Required'
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //     errors.email = 'Invalid email address'
  //   }



  return (
    <div>
      <div className='signup_page_header'>
        <img id="signup_pg_header_img" style={{ width: '10%' }} src="https://www.smscholarly.com/assets/images/5.png" alt="S&amp;M Scholarly Solutions" />

        <a href="./home" id='home_icon'> Home</a>
        <a href="./login" id='login_icon'>Login</a>
      </div>

      <div className="container">
        <div className='flex_container1'>
          <div className='signup_image_flex'>
            <img style={{ width: '110%' }} src="https://www.smscholarly.com/assets/images/5.png" alt="S&amp;M Scholarly Solutions" />
          </div>
          <div id="signup_card" className='card'>


            <h1 className="heading">Sign Up</h1>
            <p className='text'>Please fill in this form to create an account.</p>
            <div className='text_field'>
              <div className='preview'>

                <div className='preview-div'>
                  <label className='upload-button'>
                    {fileupload === 'null' &&
                      <i id="plus_icon" className='fa fa-plus' />}
                    {fileupload === 'null' && <p className="add_image_align">Add Image</p>}



                    {fileupload && <input type="file" accept="image/*" className='image-upload' onChange={readImage} />}
                    {fileupload && <img style={{ width: '30%' }} id='img_preview' className='img_preview' src='' alt='' />}

                  </label>
                </div>
              </div>

            </div>




            <div id="signup_btn1">
              {/* <button id='cncl_btn' type="button" className="cancelbtn1">
      Cancel
    </button> */}
              <button id="signupbtn1" type="submit" onClick={registerUser}>
                Sign Up
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;