import React from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import LogoWhite from './assets/images/logo5.svg'
import ReactLoading from "react-loading";
import { globaladdress, globalkey, s_key, s_secret } from "./variables";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class DataTable extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      DataisLoaded: false
    };
  }
  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getproviderapprovallist?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            items: res,
            DataisLoaded: true
          });
        }
      })
  }
  accept(e) {
    var pid = e.target.getAttribute('data-pid');
    var status = 1
    try {
      axios.post(globaladdress + "updateproviderstatus?pid=" + pid + "&status=" + status + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            window.location.reload()

          }
        });
    } catch {
      toast.error("api call skipped!!")
    }
  }
  reject(e) {
    var pid = e.target.getAttribute('data-pid');
    var status = 2
    try {
      // alert("myfunction called wwith pid:"+pid);
      axios.post(globaladdress + "updateproviderstatus?pid=" + pid + "&status=" + status + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            window.location.reload()

          }
        });
    } catch {
      toast.error("api call skipped!!");
    }
  }
  render() {
    const { DataisLoaded, items } = this.state;
    if (items === "Providers will be displayed here soon...") return <div className="full_page">
      <div className='col-md-2 side-nav'>
        <Navbar />
      </div>
      <div className='col-md-10 page-content'>
        <div className="container2">
          <h1> Providers will be displayed here soon... </h1>
        </div>
      </div> </div>;
    if (!DataisLoaded) return
    <div className='full_page'>
      <div className='col-md-2 side-nav'>
        <Navbar />
      </div>
      <div className='col-md-10 page-content'>
        <div className='container2'>
          <div className='content'>
            <div className='full_page'>
              <div className='col-md-2 side-nav'>
                <Navbar />
              </div>
              <div className='col-md-10 page-content'>
                <div className='container2'>
                  <div className='content'>
                    <div className="loader-element">
                      <img
                        className="loader-svg"
                        src={LogoWhite}
                        alt='Jagbandhu'
                      />
                      <ReactLoading
                        type="spinningBubbles"
                        color="#FFFFFF"
                        height={300}
                        width={150}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      ;
    return (
      <div className='full_page'>
        <div className='col-md-2 side-nav'>
          <Navbar />
        </div>
        <div className='col-md-10 page-content'>
          <div className='container2'>
            <div className='content'>
              <h1>Providers Details</h1>
              <div className="col-md-11">
                <table className="table" key={items[0].pId} >
                  <tr>
                    <th>firstName</th>
                    <th>lastName</th>
                    <th>mobile</th>
                    <th>email</th>
                    <th>dob</th>
                    <th>gender</th>
                    <th>displayName</th>
                    <th>district</th>
                    <th>state</th>
                    <th>country</th>
                    {/* {items.status ==='0'&&
                                <th>Approval</th>
        } */}
                    <th>Approval</th>
                  </tr>
                  {items.map((item) => (
                    <tr >
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.mobile}</td>
                      <td>{item.email}</td>
                      <td>{item.dob}</td>
                      <td>{item.gender}</td>
                      <td>{item.displayName}</td>
                      <td>{item.district}</td>
                      <td>{item.state}</td>
                      <td>{item.country}</td>
                      {item.status === '0' &&
                        <td id="approval_col">
                          <button
                            id='accept_btn'
                            data-pid={item.pId}
                            type="submit"
                            onClick={this.accept}>
                            Accept!
                          </button>
                          <button
                            id="reject_btn"
                            data-pid={item.pId}
                            type="submit"
                            onClick={this.reject}>
                            Reject!
                          </button>
                        </td>
                      }
                      {item.status === '1' &&
                        <td><FaCheck /></td>}
                      {item.status === '2' &&
                        <td><FaTimes /></td>}
                    </tr>
                  ))
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DataTable;