import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Profile extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.username,
            firstname: localStorage.firstname,
            lastname: localStorage.lastname,
            employee_mobile: localStorage.employee_mobile,
            email: localStorage.email,
            currentPassword: '',
            newPassword: '',
            showCurrentPassword: false,
            showNewPassword: false,
            Pass_input_field: '',
            isProfileChanged: false,
            initialData: {
                e_user_name: localStorage.username,
                e_first_name: localStorage.firstname,
                e_last_name: localStorage.lastname,
                e_contact_number: localStorage.employee_mobile,
                e_email: localStorage.email,
            },
        };
    }
    componentDidMount() {
        const employeeId = localStorage.employee_id
        // fetch(globaladdress + "getteammemberdet?employee_id=" + employeeId + "&key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        //     .then((res) => res.json())
        //     .then((res) => {
        //         this.setState({
        //             products: res,
        //         });
        //     });
    }
    handleInputChange = (event) => {
        const fieldId = event.target.id;
        const newValue = event.target.value;
        this.setState({
            [fieldId]: newValue,
            isProfileChanged: true,
            Pass_input_field: event.target.value
        });
    };
    handleInputChange1 = (event) => {
        const fieldId = event.target.id;
        const newValue = event.target.value;
        const { initialData } = this.state;
        if (newValue !== initialData[fieldId]) {
            this.setState({
                [fieldId]: newValue,
                isProfileChanged: true,
            });
        } else {
            this.setState({
                [fieldId]: initialData[fieldId],
            });
        }
    };
    togglePasswordVisibility = (field) => {
        this.setState((prevState) => ({
            [field]: !prevState[field],
        }));
    };
    handleUpdateProfile = () => {
        this.setState({ isProfileChanged: false });
        const u_first_name = document.getElementById("e_first_name").value;
        const u_last_name = document.getElementById("e_last_name").value;
        const u_contact_number = document.getElementById("e_contact_number").value;
        const u_email = document.getElementById("e_email").value;
        const u_employee_id = localStorage.employee_id
        toast.info(u_first_name + " " + u_last_name + " " + u_contact_number + " " + u_email + " " + u_employee_id);
        // try {
        //     axios.post(globaladdress + "getteammemberdet?current_password=" + + "&new_password=" +  + "&key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(() => { window.location.reload() })
        //     // alert("function called in try bloack");
        // } catch {
        //     alert("api call skipped!!")
        // }

    };
    handleChangePassword = () => {
        const u_curr_pass = document.getElementById("currentPassword").value;
        const u_new_pass = document.getElementById("newPassword").value;
        const pattern = /^[a-zA-Z0-9@#-]+$/;
        const u_curr_pass_length = u_curr_pass.length;
        const u_new_pass_length = u_new_pass.length;
        toast.info(u_curr_pass_length + " " + u_new_pass_length);
        const u_employee_id = localStorage.employee_id;
        if (u_new_pass_length >= 8 && u_new_pass_length <= 12 && u_curr_pass_length >= 8 && u_curr_pass_length <= 12) {
            if (pattern.test(u_new_pass) && pattern.test(u_curr_pass)) {
                if (u_curr_pass === u_new_pass) {
                    toast.warning('New password cannot be the same as the current password');
                } else {
                    axios.post(globaladdress + "updateteammemberpassword?current_password=" + u_curr_pass + "&new_password=" + u_new_pass + "&employee_id=" + u_employee_id + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                        .then(res => {
                            if (!res.ok) {
                                throw new Error(`HTTP error! Status: ${res.status}`);
                            }
                            return res.text();
                        })
                        .then((resText) => {
                            console.log("Full response:", resText);
                            if (resText === "Session timeOut!") {
                                alert("Session timeOut!");
                                window.localStorage.clear();
                                window.location.href = "/";
                            } else {
                                const res = JSON.parse(resText);
                                if (res.status === 200) {
                                    const message = res.data.message;
                                    if (message === "password updated successfully") {
                                        toast.success("Password updated successfully");
                                        window.location.reload();
                                    } else {
                                        toast.error("Password update failed: " + message);
                                    }
                                } else {
                                    toast.error("Password update failed: " + res.data.message);
                                }
                            }
                        })
                        .catch(error => {
                            toast.error("API call failed: " + error.message);
                        });
                }
            } else {
                toast.error("Invalid Password Format! 'only A-Z and 0-1 and -,@,# are accepted");
            }
        } else {
            toast.warning("Please enter a valid password! range from (8-12)");
        }
    };
    render() {
        const { Pass_input_field } = this.state;
        const isButtonDisabled = Pass_input_field.length === 0;
        const { isProfileChanged } = this.state;
        const isButtonDisabled1 = !isProfileChanged;
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    <Header />
                    <div className='right_col' id='right_col_height'>
                        <div className='table_container'>
                            <div className='hero_content'>
                                <div className='profile_container'>
                                    <div className='profile_details_card'>
                                        <div style={{ width: "60rem", background: "#fff", borderRadius: "10px", boxShadow: "0px 0px 10px 5px #cecece", padding: "20px 0px 10px 40px", height: "70vh", width: "25vw" }}>
                                            <div>
                                                <h2 style={{ fontSize: "25px" }}><strong>Profile details</strong></h2>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-start", alignItems: "center" }}>
                                                <div className="profile_pic">
                                                    <img
                                                        className="img-circle profile_img"
                                                        src="Stock.png"
                                                        alt="Jagbandhu"
                                                        style={{ width: "100px", marginLeft: '0px', marginRight: '70px' }}
                                                    />
                                                </div>
                                                <div className='profile_details_container'>
                                                    <h2>Employee Id: {localStorage.employee_id}</h2>
                                                    <h2>Role: {localStorage.role}</h2>
                                                    <h2>User Name: {localStorage.username}</h2>
                                                </div>
                                            </div>
                                            <br />
                                            <div style={{ paddingTop: '20px' }}>
                                                <label>
                                                    First Name:<br />
                                                    <input
                                                        className='profile_details_container_input_fields'
                                                        id='e_first_name'
                                                        type='text'
                                                        defaultValue={localStorage.firstname}
                                                        onChange={this.handleInputChange1}>
                                                    </input>
                                                </label><br /><br />
                                                <label>
                                                    Last Name:<br />
                                                    <input
                                                        className='profile_details_container_input_fields'
                                                        id='e_last_name'
                                                        type='text'
                                                        defaultValue={localStorage.lastname}
                                                        onChange={this.handleInputChange1}>
                                                    </input>
                                                </label><br /><br />
                                                <label>
                                                    Contact Number:<br />
                                                    <input
                                                        className='profile_details_container_input_fields'
                                                        id='e_contact_number'
                                                        type='number'
                                                        defaultValue={localStorage.employee_mobile}
                                                        onChange={this.handleInputChange1}>
                                                    </input>
                                                </label><br /><br />
                                                <label>
                                                    Email:<br />
                                                    <input
                                                        className='profile_details_container_input_fields'
                                                        id='e_email'
                                                        type='email'
                                                        defaultValue={localStorage.email}
                                                        onChange={this.handleInputChange1}>
                                                    </input>
                                                </label>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <button
                                                    className="profile_details_btn"
                                                    style={{ width: "20vw" }}
                                                    onClick={this.handleUpdateProfile}
                                                    disabled={isButtonDisabled1}>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                        <div className='pro_pass_flex_container'>
                                            <div className='profile_password_container'>
                                                <div className="profile_password_container_fields">
                                                    <div>
                                                        <h2 style={{ fontSize: "25px" }}>Change Password</h2>
                                                    </div>
                                                    <br />
                                                    <label>
                                                        Current Password<br />
                                                        <div className="password-input">
                                                            <input
                                                                className="profile_details_container_input_fields"
                                                                id="currentPassword"
                                                                type={this.state.showCurrentPassword ? 'text' : 'password'}
                                                                value={this.state.currentPassword}
                                                                onChange={this.handleInputChange}
                                                                minLength={8}
                                                                maxLength={12}
                                                            />
                                                            <i
                                                                className={`eye-icon ${this.state.showCurrentPassword ? 'visible' : ''}`}
                                                                onClick={() => this.togglePasswordVisibility('showCurrentPassword')}
                                                            >
                                                                👁️
                                                            </i>
                                                        </div>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        New Password<br />
                                                        <div className="password-input">
                                                            <input
                                                                className="profile_details_container_input_fields"
                                                                id="newPassword"
                                                                type={this.state.showNewPassword ? 'text' : 'password'}
                                                                value={this.state.newPassword}
                                                                onChange={this.handleInputChange}
                                                                minLength={8}
                                                                maxLength={12}
                                                            />
                                                            <i
                                                                className={`eye-icon ${this.state.showNewPassword ? 'visible' : ''}`}
                                                                onClick={() => this.togglePasswordVisibility('showNewPassword')}
                                                            >
                                                                👁️
                                                            </i>
                                                        </div>
                                                    </label>
                                                </div>
                                                <br />
                                                <div className='profile_details_btn_cntr'>
                                                    <button
                                                        className="profile_details_btn"
                                                        id='profile_det_btn_styles'
                                                        style={{ width: "20vw", style: "vertical-align:middle" }}
                                                        onClick={this.handleChangePassword}
                                                        disabled={isButtonDisabled}>
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='profile_activity_card'>
                                                <h2 style={{ fontSize: "25px" }}>Activity card</h2>
                                                <br />
                                                <h4>User Activities</h4>
                                                <ul>
                                                    <li>No listed activities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default Profile;