import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import { FaPlus, FaEdit, FaBars, } from "react-icons/fa";
import { IoIosArrowForward, IoIosCloseCircle, IoMdCheckmarkCircle } from "react-icons/io";
// import { FaBars } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import 'react-toastify/dist/ReactToastify.css';
import './surveys.css'

class Products extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            surveys: [],
            surveyShrinkStatus: '',
            activeSurveyDetTab: 'Questions',
            show_add_question_form: false,
            show_edit_question_form: false,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
            selectedSurveyTitle: '',
            selectedSurveyDescription: '',
            selectedSurveyId: '',
            selectedSurveyStatus: '',
            selectedResponseId: [],
            surveyQuestions: [],
        };
        this.addQuestion = this.addQuestion.bind(this);
        this.editQuestion = this.editQuestion.bind(this);

    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getjbsurveys?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        surveys: res,
                    });
                }
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });

    }
    tooglrSurvey = () => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ show_add_survey_form: !this.state.show_add_survey_form });
        }
    }
    toogleAddQuestion = () => {
        this.setState({
            show_add_question_form: !this.state.show_add_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
        });
    }
    toogleEditQuestion = (e) => {
        e.preventDefault();
        this.setState({
            show_edit_question_form: !this.state.show_edit_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',

        });
    }
    handleQuestionTypeChange = (e) => {
        this.setState({
            questionType: e.target.value,
            options: e.target.value === 'options' ? [] : this.state.options,
        });
    };
    handleQuestionTypeChangeEdit = (e) => {
        this.setState({
            questionType: e.target.value,
            options: e.target.value === 'options' ? [] : this.state.options,
        });
    }
    handleOptionChange = (e) => {
        this.setState({ newOption: e.target.value });
    };
    handleEditOptionChange = (index, newValue) => {
        const options = [...this.state.options];
        options[index] = newValue;
        this.setState({ options });

    }
    handleAddOption = () => {
        const newOption = this.state.newOption.trim(); // Trim whitespace from the new option
        if (newOption) {
            const options = [...this.state.options, newOption]; // Add the new option to the options array
            this.setState({ options, newOption: '' }); // Clear the newOption state after adding it
        }
    }

    handleRemoveOption = (index) => {
        const options = [...this.state.options];
        options.splice(index, 1);
        this.setState({ options });
    }
    addOption = () => {
        const { newOption, options } = this.state;
        if (newOption.trim() !== '') {
            this.setState({
                options: [...options, newOption],
                newOption: '',
            });
        }
    };

    addSurvey(event) {
        event.preventDefault();
        var dataset = {
            survey_title: document.getElementById('survey_title').value,
            survey_description: document.getElementById('survey_description').value,
            survey_reward: document.getElementById('survey_reward').value

        }
        if (dataset.survey_description === "" || dataset.survey_description === null || dataset.survey_description === undefined || dataset.survey_reward === "" || dataset.survey_reward === null || dataset.survey_reward === undefined || dataset.survey_title === "" || dataset.survey_title === null || dataset.survey_title === undefined) {
            alert("check the input fields & check the right format & try again later");
        } else {
            try {
                axios.post(globaladdress + "addjbsurvey?survey_title=" + dataset.survey_title + "&survey_description=" + dataset.survey_description + "&reward=" + dataset.survey_reward + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(() => { window.location.reload() })
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            window.location.reload()
                        }
                    })
            } catch {
                alert("Something went wrong while adding a new Survey!\nPlease try again later.");
            }
        }
        alert(dataset.survey_description);
        alert(dataset.survey_reward);
        alert(dataset.survey_title);
    }
    addQuestion(event) {
        event.preventDefault();
        const newOption = this.state.newOption;
        const updatedOptions = [...this.state.options, newOption];

        // Filter out empty options
        const nonEmptyOptions = updatedOptions.filter(option => option.trim() !== "");

        // Serialize options array
        const serializedOptions = nonEmptyOptions.map((option, index) => `"option_${index + 1}":"${option}"`);

        this.setState({
            show_question: !this.state.show_question,
            options: updatedOptions,
            newOption: '',
        });

        var dataset = {
            surveyId: this.state.selectedSurveyId,
            questionText: this.state.question,
            options: `{${serializedOptions.join(',')}}`,
            questionType: this.state.questionType
        };

        if (
            dataset.questionText === "" || dataset.questionText === null || dataset.questionText === undefined ||
            dataset.questionType === "" || dataset.questionType === null || dataset.questionType === undefined ||
            (dataset.questionType === "options" && serializedOptions.length === 0)
        ) {
            alert("Check the input fields & check the right format & try again later");
        } else {
            try {
                // Convert dataset to query string
                const queryString = Object.keys(dataset)
                    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dataset[key]))
                    .join('&');

                axios.post(`${globaladdress}addjbsurveyquestions?${queryString}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.text();
                    })
                    .then(responseText => {
                        console.log("Full response:", responseText);
                        if (responseText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            window.location.reload();
                        }
                    });
            } catch {
                alert("Something went wrong while adding a new Survey!\nPlease try again later.");
            }
        }
    }

    editQuestion(e) {
        const { question, questionType, options, questionId, selectedSurveyId } = this.state;

        // Convert options array to an object with keys in the format of "option_1", "option_2", etc.
        const optionsObject = {};
        options.forEach((option, index) => {
            optionsObject[`option_${index + 1}`] = option;
        });

        // Convert options object to a string
        const optionsString = JSON.stringify(optionsObject);

        console.log(`Question Text: ${question}\nResponse Type: ${questionType}\nResponse Options: ${optionsString}\nQuestionId: ${questionId}\nSurveyId: ${selectedSurveyId}`);
        alert(`Question Text: ${question}\nResponse Type: ${questionType}\nResponse Options: ${optionsString}\nQuestionId: ${questionId}\nSurveyId: ${selectedSurveyId}`);
        try {
            axios.post(`${globaladdress}editjbsurveyquestion?question=${question}&questionType=${questionType}&options=${optionsString}&questionId=${questionId}&surveyId=${selectedSurveyId}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.text();
                })
                .then(responseText => {
                    console.log("Full response:", responseText);
                    if (responseText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload();
                    }
                });
        } catch {
            alert("Something went wrong while adding a new Survey!\nPlease try again later.");
        }
    }


    handleSurveyClick = (surveyId, surveyTitle, surveyDescription, surveyStatus) => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            const element = document.querySelector('.added_surveys_container');
            const element1 = document.querySelector('.survey_page_flex_container');
            const element2 = document.querySelector('.sp1');
            const computedWidth = window.getComputedStyle(element).width;
            axios.get(globaladdress + "getsurveyquestions?surveyId=" + surveyId + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((response) => {
                    console.log("Survey Questions Data:", response.data);
                    this.setState({
                        show_question: true,
                        selectedSurveyId: surveyId,
                        selectedSurveyTitle: surveyTitle,
                        selectedSurveyDescription: surveyDescription,
                        selectedSurveyStatus: surveyStatus,
                        surveyQuestions: response.data,
                    })
                })
                .catch((error) => {
                    console.error('Error fetching survey questions:', error);
                });

            if (computedWidth === '30%') {
                this.setState({ surveyShrinkStatus: 'shrink' }, () => {
                    element2.style.display = 'block';
                });
            } else {
                this.setState({ surveyShrinkStatus: 'noshrink' }, () => {
                    element.style.width = '30%';
                    element1.style.display = 'flex';
                    element1.style.flexDirection = 'row';
                    element1.style.flexWrap = 'nowrap';
                    element1.style.justifyContent = 'flexStart';
                    element1.style.alignItems = 'flexStart';
                    element2.style.display = 'none';
                });
            }
        }
    }
    openSurveyDetTab = (surveyTabName) => {
        this.setState({ activeSurveyDetTab: surveyTabName });
    };
    handleEditQuestion = (questionText, responseType, responseData, questionId, surveyId) => {
        // Filter out the options from the responseData object
        const optionsArray = Object.keys(responseData)
            .filter(key => key.startsWith('option_'))
            .map(key => responseData[key]);

        const selectedResponseIds = this.state.surveyQuestions
            .filter(question => question.jb_survey_id === surveyId && question.jb_survey_question_id === questionId)
            .map(question => question.response_id)
            .filter(id => id !== null);

        this.setState({
            show_edit_question_form: !this.state.show_edit_question_form,
            question: questionText,
            questionType: responseType,
            options: optionsArray,
            questionId: questionId,
            selectedSurveyId: surveyId,
            selectedResponseId: selectedResponseIds
        });
    }



    handleDeleteQuestion = (surveyQuestion, surveyId, questionId) => {
        alert(`surveyId: ${surveyId}\n QuestionId: ${questionId}`);
        axios.delete(globaladdress + "deletesurveyquestion?surveyId=" + surveyId + "&questionId=" + questionId + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                console.log("Full response:", resText);
                if (resText === "Session timeOut!") {
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    window.location.reload()
                }
            })
    }
    handleToggleChange = () => {
        const surveyId = this.state.selectedSurveyId;
        const newStatus = this.state.selectedSurveyStatus === "1" ? "0" : "1";
        this.setState({ selectedSurveyStatus: newStatus });
        try {
            axios.post(globaladdress + "updatesurveystatus?surveyId=" + surveyId + "&surveyStatus=" + newStatus + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        } catch {
            alert(`Venturing into realms beyond the grasp of logic, where the ordinary surrenders to the extraordinary. `)
        }
        // Trigger alert function here
        alert(`Survey status changed to ${newStatus === "1" ? 'ON' : 'OFF'}`);
    }
    render() {
        const { activeSurveyDetTab, show_add_question_form, questionType, options, newOption, show_edit_question_form } = this.state;
        const { surveyQuestions } = this.state;
        if (typeof surveyQuestions !== 'object' || surveyQuestions === null) {
            console.error('Invalid surveyQuestion data:', surveyQuestions);
            return <p>Error loading survey question</p>;
        }
        return (
            <div>
                <div className="container body">
                    <div className='main_container'>
                        <div className='col-md-3 left_col'>
                            <Navbar username={localStorage.username} />
                        </div>
                        {/* <div className='page-content'> */}
                        <Header />
                        <div className='right_col'>
                            <div className='table_container'>
                                <div className='survey_container'>
                                    <div className='sp1'>
                                        <div className='survey_container_header'>
                                            <div className='survey_page_main_title'>
                                                <h1>Surveys</h1>
                                            </div>
                                            <div className='add_survey_btn'>
                                                <button className="add_survey_btn" onClick={this.tooglrSurvey}> <FaPlus style={{ background: '#fff', color: '026FBF', fontSize: '30px', borderRadius: '15px', padding: '5px' }} />&ensp; Add Survey</button>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    {/* flex container */}
                                    <div className='survey_page_flex_container'>
                                        {/* list of surveys container */}
                                        <div className='added_surveys_container' style={{ display: "flex", flexWrap: 'nowrap', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100%', gap: '20px' }}>
                                            {this.state.surveys && this.state.surveys.map((s, index) => (
                                                <div style={{ width: '100%', height: '0%' }}>
                                                    <div key={index} className='added_surveys_container_content' onClick={() => this.handleSurveyClick(s.jb_survey_id, s.survey_name, s.description, s.survey_status)} >
                                                        <div className='added_surveys_container_content_text'>
                                                            <div className='added_surveys_container_content_text_title'>
                                                                <div>
                                                                    <h2 className='survey_info'>{s.survey_name}</h2>
                                                                </div>
                                                                <div className='added_surveys_container_content_text_stats'>
                                                                    <div className='added_surveys_container_content_text_icon'>
                                                                        <div className='added_surveys_container_content_text_icon_stats'>
                                                                            <div>
                                                                                {s.survey_status === "0" ? (
                                                                                    <IoIosCloseCircle />
                                                                                ) : (
                                                                                    <IoMdCheckmarkCircle />
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: '10px', paddingLeft: '5px', paddingTop: '6px' }}>
                                                                                    {s.survey_status === "0" ? 'Not published' : 'Published'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='added_surveys_container_content_text_counts'>
                                                                <div className='added_surveys_container_content_text_questions'>
                                                                    <p>{s.no_of_questions} Questions</p>
                                                                </div>
                                                                <div className='added_surveys_container_content_text_responses'>
                                                                    <p>{s.responses} Responses</p>
                                                                </div>
                                                                <div className='added_surveys_container_content_text_rewards'>
                                                                    <p>{s.reward} Rewards</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='added_surveys_container_content_icon'>
                                                            <h1><IoIosArrowForward className='added_surveys_container_content_icon_main' style={{ color: '#026FBF' }} /></h1>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                        {/* add questions container */}
                                        <div className='survey_page_content_add_question_container'>
                                            {this.state.show_question && (
                                                <div>
                                                    <div className="add_question_container_content">
                                                        <div>
                                                            <div className='add_questions_survey_details_header_flex'>
                                                                <div className='add_questions_survey_details_header'>
                                                                    <h1>{this.state.selectedSurveyTitle}</h1>
                                                                    <h2>{this.state.selectedSurveyDescription}</h2>
                                                                </div>
                                                                <div>
                                                                    <label className="toggle-switch">
                                                                        <input type="checkbox" checked={this.state.selectedSurveyStatus === "1"} onChange={this.handleToggleChange} />
                                                                        <span className="slider"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='suvey_details_tabs_container'>
                                                                <div className="survey_det_container_tabs">
                                                                    <button className={activeSurveyDetTab === 'Responses' ? 'active-tab' : ''} id='b1' onClick={() => this.openSurveyDetTab('Responses')}>Responses</button>
                                                                    <button className={activeSurveyDetTab === 'Questions' ? 'active-tab' : ''} id='b2' onClick={() => this.openSurveyDetTab('Questions')}>Questions</button>
                                                                </div>
                                                                <div>
                                                                    <button className='add_questions-button' onClick={this.toogleAddQuestion}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <FaPlus style={{ background: '#fff', color: '026FBF', fontSize: '20px', borderRadius: '15px', padding: '5px' }} />
                                                                            <span style={{ marginLeft: '5px' }}>New Question</span>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='survey_questions_container'>
                                                                <div id="Responses" className={`w3-container w3-border city ${activeSurveyDetTab === 'Responses' ? '' : 'hidden'}`}>
                                                                    <h2>Responses</h2>
                                                                    <p>Responses will be displayed here!</p>
                                                                </div>
                                                                <div id="Questions" className={`w3-container w3-border city ${activeSurveyDetTab === 'Questions' ? '' : 'hidden'}`}>
                                                                    {this.state.surveyQuestions && this.state.surveyQuestions.length > 0 ? (
                                                                        this.state.surveyQuestions.map((surveyQuestion, index) => (
                                                                            <div className='questions_container' key={surveyQuestion.jb_survey_question_id}>
                                                                                <div className='questions_container_bars_icon'>
                                                                                    <FaBars style={{ color: '#777', width: "21px", height: "14px" }} />
                                                                                </div>
                                                                                <div className='Question_container_block'>
                                                                                    <div className='questions_container_question_text'>
                                                                                        <p><strong>Q:</strong> {surveyQuestion.question_text}</p>
                                                                                    </div>
                                                                                    <div className='question_container_question_options'>
                                                                                        <p><strong>Type:</strong> {surveyQuestion.response_type}&emsp;</p>
                                                                                        {surveyQuestion.response_type === 'options' && surveyQuestion.jb_survey_id === this.state.selectedSurveyId && (
                                                                                            <div style={{ display: "flex" }}>
                                                                                                <p><strong>Options:&nbsp;</strong></p>
                                                                                                {Object.keys(surveyQuestion).filter(key => key.startsWith('option_')).length > 0 ? (
                                                                                                    Object.keys(surveyQuestion).filter(key => key.startsWith('option_')).map((key, index, array) => (
                                                                                                        <span key={key}>{surveyQuestion[key]}{index !== array.length - 1 ? ', ' : '\u00A0'}</span>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <span>No options available</span>
                                                                                                )}

                                                                                            </div>

                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='questions_container_buttons_container'>
                                                                                    <button
                                                                                        className="questions_container_buttons"
                                                                                        title='Edit Question'
                                                                                        onClick={() => this.handleEditQuestion(
                                                                                            surveyQuestion.question_text,
                                                                                            surveyQuestion.response_type,
                                                                                            surveyQuestion, // Pass the entire surveyQuestion object
                                                                                            surveyQuestion.jb_survey_question_id,
                                                                                            surveyQuestion.jb_survey_id
                                                                                        )}
                                                                                    >
                                                                                        <FaEdit style={{ color: "#026FBF", width: "24px", height: "24px" }} />
                                                                                    </button>

                                                                                    <button className="questions_container_buttons" title="Delete Question" onClick={() => this.handleDeleteQuestion(surveyQuestion.question_text, surveyQuestion.jb_survey_id, surveyQuestion.jb_survey_question_id)}>
                                                                                        <RiDeleteBin6Line style={{ color: "#026FBF", width: "24px", height: "24px" }} />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>No questions available.</div>
                                                                    )}


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='survey_page_content_container'>
                                        <div className='create_survey_section'>
                                            <div className='create_survey'>
                                            </div>
                                        </div>
                                        {this.state.show_add_survey_form && (
                                            <div className="edit_app_notes">
                                                <div className="edit_app_notes-inner">
                                                    <h2>
                                                        Add Survey</h2>
                                                    <br></br>
                                                    <form onSubmit={this.addSurvey}>
                                                        <br />
                                                        <label>
                                                            Survey Title:
                                                            <input id='survey_title' type="text" />
                                                        </label>
                                                        <br />
                                                        <label>
                                                            Description:
                                                            <textarea id='survey_description' />
                                                        </label>
                                                        <label>
                                                            Reward amount
                                                            <input id='survey_reward' type='number'></input>
                                                        </label>
                                                        <br />
                                                        <div className='editreleasenotespopupform'>
                                                            <button type="submit">Update</button>
                                                            <button className="close-btn" onClick={this.tooglrSurvey} style={{ float: 'right' }}>
                                                                Close form
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='survey_page_add_question_form_container'>
                                        {show_add_question_form && (
                                            <div className="edit_app_notes">
                                                <div className="add_question-inner">
                                                    <h2>Add Question</h2>
                                                    <br></br>
                                                    <form onSubmit={this.addQuestion}>
                                                        <br />
                                                        <input
                                                            className='add_question_text_inputfield'
                                                            id='add_question_text_inputfield'
                                                            placeholder='Enter your question'
                                                            value={this.state.question}
                                                            onChange={(e) => this.setState({ question: e.target.value })}
                                                        />
                                                        <br />
                                                        <select
                                                            className='question_response_type'
                                                            defaultValue=""
                                                            onChange={this.handleQuestionTypeChange}
                                                        >
                                                            <option value="" disabled>Select question type</option>
                                                            <option value='text'>Single choice</option>
                                                            <option value='options'>Multiple choices</option>
                                                        </select>
                                                        <br />
                                                        {questionType === 'options' && (
                                                            <div>
                                                                <input
                                                                    placeholder='Enter option'
                                                                    value={newOption}
                                                                    onChange={this.handleOptionChange}
                                                                />
                                                                <button className='add_question_option_button' type="button" onClick={this.addOption}>Add Option</button>
                                                                <br />
                                                                {options.map((option, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <div className='add_question_lable'>
                                                                                <input type="radio" name="options" value={option} disabled />
                                                                                <p>{option}</p>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <br />
                                                        <div className='editreleasenotespopupform'>
                                                            <button type="submit">Submit</button>
                                                            <button className="close-btn" onClick={this.toogleAddQuestion} style={{ float: 'right' }}>
                                                                Close form
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='survey_page_add_question_form_container'>
                                        {show_edit_question_form && (
                                            <div className="edit_app_notes">
                                                <div className="add_question-inner">
                                                    <h2>Edit Question</h2>
                                                    <br></br>
                                                    <form onSubmit={this.editQuestion}>
                                                        <br />
                                                        <input
                                                            className='add_question_text_inputfield'
                                                            id='add_question_text_inputfield'
                                                            placeholder='Enter your question'
                                                            value={this.state.question}
                                                            onChange={(e) => this.setState({ question: e.target.value })}
                                                        />

                                                        <br />
                                                        <select
                                                            className='question_response_type'
                                                            defaultValue={this.state.questionType} // Set default value from state
                                                            onChange={this.handleQuestionTypeChangeEdit}
                                                        >
                                                            <option value="" disabled>Select question type</option>
                                                            <option value='text'>Single choice</option>
                                                            <option value='options'>Multiple choices</option>
                                                        </select>
                                                        <br />
                                                        {this.state.questionType === 'options' && (
                                                            <div>
                                                                {this.state.options.map((option, index) => (
                                                                    <div key={index}>
                                                                        <input
                                                                            placeholder='Enter option'
                                                                            value={option}
                                                                            onChange={(e) => this.handleEditOptionChange(index, e.target.value)}
                                                                        />
                                                                        <button type="button" onClick={() => this.handleRemoveOption(index)}>Remove</button>
                                                                    </div>
                                                                ))}
                                                                <div>
                                                                    <input
                                                                        placeholder='Enter new option'
                                                                        value={this.state.newOption}
                                                                        onChange={(e) => this.setState({ newOption: e.target.value })}
                                                                    />
                                                                    <button className='add_question_option_button' type="button" onClick={this.handleAddOption}>Add Option</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <br />
                                                        <div className='editreleasenotespopupform'>
                                                            <button type="submit">Submit</button>
                                                            <button className="close-btn" onClick={this.toogleEditQuestion} style={{ float: 'right' }}>
                                                                Close form
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default Products;