import React, { useEffect, useState } from 'react'
import axios from "axios";
import styled from 'styled-components'
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Styles = styled.div`
  padding: 1rem;
  table {
  border-spacing: 0;
  border: 1px solid black;
  width:80%;
  margin:10px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th,td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    :last-child {
      border-right: 0;
    }
  }
}
`
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  return (
    <span>
      Search:{''}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}
function DefaultColumnFilter({
}) {
  return (
    <div></div>
  )
}
function Table({ columns, data }) {
  const filterTypes = (
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    user_role_acc = window.localStorage.getItem("role"),
    showForm,
    state: {
      pageIndex,
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )
  const firstPageRows1 = page.slice(0, 10);
  return (
    <>
      <h1>Members</h1>
      <table className='data_table' {...getTableProps()}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows1.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <div id='filter_det'>
        Showing the{" "}
        {pageIndex * 10 + 1}-
        {(pageIndex + 1) * 10 > rows.length ? rows.length : (pageIndex + 1) * 10} results of {rows.length} rows      </div>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"First"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Last"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const rows = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(rows);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}

      </div>
    </>
  )
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = val => typeof val !== 'number'
function App() {
  const role = localStorage.role;
  const updateData = (event) => {
    console.log(event.target.dataset.editedaddress);

    const editedTmId = event.target.dataset.editedtmid;
    const editedFirstName = event.target.dataset.editedfirstname;
    const editedLastName = event.target.dataset.editedlastname;
    const editedEmail = event.target.dataset.editedemail;
    const editedDOB = event.target.dataset.editeddob;
    const editedMobile = event.target.dataset.editedmobile;
    const editedGender = event.target.dataset.editedgender;
    const editedAddress = event.target.dataset.editedaddress;
    const editedRole = event.target.dataset.editedrole;
    const editedUserName = event.target.dataset.editedusername;

    axios.post(`${globaladdress}updateteamemberdetails?tmId=${editedTmId}&firstName=${editedFirstName}&lastName=${editedLastName}&email=${editedEmail}&dob=${editedDOB}&mobile=${editedMobile}&gender=${editedGender}&address=${editedAddress}&role=${editedRole}&userName=${editedUserName}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          console.log('Member details updated successfully');
          closeEditModal();
          window.location.reload();
        }

      })
      .catch((error) => {
        console.log("Axios error: ", error)
      });
  };
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  var [selectedRow, setSelectedRow] = useState(null);
  const openEditModal = (row) => {
    alert("edit form fun called");
    const role = localStorage.role
    if ((role == "Admin")) {
      setEditModalOpen(true);
      setSelectedRow(row);
    } else {
      alert("Action not approved")
    }
  };
  const closeEditModal = () => {
    setSelectedRow(null);
    setEditModalOpen(false);
  };
  const deletemember = () => {
    const memberId = document.getElementById('f_memberId').value;
    toast.info(memberId);

    axios.delete(`${globaladdress}deletemember?tsId=${memberId}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          console.log('Team member deleted successfully');
          closeEditModal();
          window.location.reload();
        }

      })
      .catch((error) => {
        console.log("Axios error: ", error);
      });
  }
  const populateEditForm = (e) => {
    const showAlert = () => {
      toast('Button Clicked!');
    };
    if (!selectedRow) return null;
    const tdId = selectedRow.original.tdId;
    const FirstName = selectedRow.original.firstName;
    const LastName = selectedRow.original.lastName;
    const Email = selectedRow.original.email;
    const DOB = selectedRow.original.dob;
    const Mobile = selectedRow.original.mobile;
    const Gender = selectedRow.original.gender;
    const Address = selectedRow.original.address;
    const Role = selectedRow.original.role;
    const UserName = selectedRow.original.userName;


    const editedTmId = document.getElementById('f_memberId') || tdId;
    const editedFirstName = document.getElementById('f_first_name') || FirstName;
    const editedLastName = document.getElementById('f_last_name') || LastName;
    const editedEmail = document.getElementById('f_email') || Email;
    const editedDOB = document.getElementById('f_dob1') || DOB;
    const editedMobile = document.getElementById('f_mobile') || Mobile;
    const editedGender = f_s_gender || Gender;
    const editedAddress = document.getElementById('f_address') || Address;
    const editedRole = f_s_role || Role;
    const editedUserName = document.getElementById('f_user_name') || UserName;

    const data12 = {
      editedTmId,
      editedFirstName,
      DOB,
      editedLastName,
      editedEmail,
      editedDOB,
      editedMobile,
      editedGender,
      editedAddress,
      editedRole,
      editedUserName,
    }

    return (
      <>
        <div className="addrelation_form">
          <label style={{ width: '150px', display: 'inline-block' }}>Member Id:</label>
          <input id="f_memberId" type="text" defaultValue={tdId} disabled />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>First Name:</label>
          <input id="f_first_name" type="text" defaultValue={FirstName} />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Last Name:</label>
          <input id="f_last_name" type="text" defaultValue={LastName} />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Email:</label>
          <input id="f_email" type="text" defaultValue={Email} />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Mobile:</label>
          <input id="f_mobile" type="number" defaultValue={Mobile} />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Gender:</label>
          <select value={f_s_gender} onChange={handleGenderChange} defaultValue={Gender}>
            <option>{Gender}</option>
            <option value={"Male"}>Male</option>
            <option value={"Female"}>Female</option>
          </select>
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Address:</label>
          <input id="f_address" type="text" defaultValue={Address} />
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>Role:</label>
          <select value={f_s_role} onChange={handleRoleChange}>
            <option>{Role}</option>
            <option value={"User"}>User</option>
            <option value={"Admin"}>Admin</option>
            <option value={"Power User"}>Power User</option>
            <option value={"Viewer"}>Viewer</option>
            <option value={"Operator"}>Operator</option>
            <option value={"In Active Role"}>In Active Role</option>
          </select>
          <br /><br />
          <label style={{ width: '150px', display: 'inline-block' }}>User Name:</label>
          <input id="f_user_name" type="text" defaultValue={UserName} />
          <br /><br />
          <div className="dob-field">
            <label style={{ width: '150px', display: 'inline-block' }}>DOB:</label>
            <DatePicker
              name="memberdob"
              className="readonly-datepicker"
              id="f_dob1"
              type="text"
              size="sm"
              placeholderText={DOB}
              selected={selectedDate}
              maxDate={new Date()}
              value={setSelectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>

          <Modal.Footer>
            <div className='edit_rel_lkp_model_btn'>
              <Button id='rel_lkp_update_btn' variant="primary" onClick={updateData}
                data-editedtmid={data12.editedTmId}
                data-editedaddress={data12.editedAddress}
                data-editeddob={data12.editedDOB}
                data-editedemail={data12.editedEmail}
                data-editedfirstname={data12.editedFirstName}
                data-editedgender={data12.editedGender}
                data-editedlastname={data12.editedLastName}
                data-editedmobile={data12.editedMobile}
                data-editedrole={data12.editedRole}
                data-editedusername={data12.editedUserName}
              >
                Update
              </Button>
              <Button id='delete_member_btn' onClick={deletemember}>Delete</Button>
            </div>
          </Modal.Footer>
        </div>
      </>
    );
  };
  function add() {
    var m_firstname = document.getElementById("f_first_name").value;
    var m_lastname = document.getElementById("f_last_name").value;
    var m_email = document.getElementById("f_email").value;
    var m_dob = document.getElementById("f_dob").value;
    var m_mobile = document.getElementById("f_mobile").value;
    var m_gender = ''; // Set default value if empty
    var m_address = document.getElementById("f_address").value;
    var m_username = document.getElementById("f_user_name").value;
    var m_role = ''; // Set default value if empty
    if (m_gender === '') {
      m_gender = 'Male'
    } else {
      m_gender = f_s_gender
    } if (m_role === '') {
      m_role = 'User'
    } else {
      m_role = f_s_role
    }
    // const roleDropdown = document.getElementById("f_role");
    // const m_role = roleDropdown ? roleDropdown.value : 'User';
    // You can now use these variables to send the data to your API or perform other actions
    // alert("First Name: " + m_firstname);
    // alert("Last Name: " + m_lastname);
    // alert("Email: " + m_email);
    // alert("DOB: " + m_dob);
    // alert("Mobile: " + m_mobile);
    // alert("Gender: " + m_gender);
    // alert("Address: " + m_address);
    // alert("User Name: " + m_username);
    // alert("Role: " + m_role);


    if (m_firstname && m_lastname && m_email) {
      // alert("this is this")
      // // axios.post(globaladdress+"adminaddmember?userName="+m_username+"&dob="+m_dob+"&mobileNumber="+m_mobile+"&key="+globalkey).then(()=>{window.location.reload()})
      axios.post(globaladdress + "adminaddmember?firstName=" + m_firstname + "&lastName=" + m_lastname + "&email=" + m_email + "&gender=" + m_gender + "&address=" + m_address + "&role=" + m_role + "&userName=" + m_username + "&dob=" + m_dob + "&mobileNumber=" + m_mobile + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        .then(response => {
          if (response.status === 200) {
            if (response.data === "Session timeOut!") {
              alert("Session timeOut!");
              window.localStorage.clear();
              window.location.href = "/";
            } else {
              alert("Team member added successfully");
              window.location.reload();
            }
          } else {
            alert(`Internal server error \n Error Status code: ${response.status}`);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        })
        .catch(error => {
          console.error("Axios error:", error);
          alert("Something went wrong while adding a new Tag!\nPlease try again later.");
        });

    } else {
      toast.warning("Please fill the required feilds!")
    }
  }
  function rolecheck() {
    var role_t = window.localStorage.getItem("role");
    console.log(role_t)
    if (role_t === "User") {
      document.querySelector(".addrelbtn").style.display = "none";
    }
  }
  const [selectedDate, setSelectedDate] = useState(null);


  const handleDateChange = (date) => {
    setSelectedDate(date);
    // document.getElementById("f_dob").readOnly = true;

  };
  const handleDateChange1 = (date) => {
    setSelectedDate(date);
    // document.getElementById("f_dob").readOnly = true;

  };
  const [data2, setData2] = useState([]);
  const [showModal, setModal] = React.useState(false);
  const [data, setData] = useState([]);
  function handleShow() { setModal(true) }
  function handleClose() { setModal(false) }
  useEffect(() => {
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getmemberslist?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          setData(res)
        }
      }).catch((error) => {
        console.error("Fetch error: ", error);
      })
    rolecheck();
  }, [])
  const columns = [{
    Header: "Member's",
    columns: [
      {
        Header: 'Sl No.',
        accessor: 'tdId',
        Footer: 'Sl No.',
      },
      {
        Header: 'Employee Id',
        accessor: 'employee_id',
        Footer: 'Employee Id',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        Footer: 'First Name',
      },
      {
        Header: 'Last Id',
        accessor: 'lastName',
        Footer: 'Relation Id',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Footer: 'Relation Id',
      },
      {
        Header: 'Date of Birth',
        accessor: 'dob',
        Footer: 'Date of Birth',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        Footer: 'Mobile',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Footer: 'Gender',
      },
      {
        Header: 'Address',
        accessor: 'address',
        Footer: 'Address',
      },
      {
        Header: 'Role',
        accessor: 'role',
        Footer: 'Role',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Footer: 'Status',
      },
      {
        Header: 'User Name',
        accessor: 'userName',
        Footer: 'User Name',
      },
      {
        Header: "Action",
        accessor: "modifymember",
        Footer: "Action",
        Cell: ({ row }) => (
          <>
            <button id='edit_relation_lkp_btn' onClick={() => openEditModal(row)}>Edit</button>
          </>
        ),
      }
    ],
  },
  ]
  const [f_s_gender, setGender] = useState('');
  const [f_s_role, setRole] = useState('');
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  }
  const handleGenderChange = (event) => {
    setGender(event.target.value);

  };



  return (
    <div className="container body">
      <div className='main_container'>
        <div className='col-md-3 left_col'>
          <Navbar username={localStorage.username} />
        </div>
        {/* <div className='page-content'> */}
        <Header />
        <div className='right_col'>
          <div className='container2'>
            <div className='content'>
              <Styles>
                <div>
                  <div className="addrelbtn">
                    <button className='add_member_btn' id="showform" type="submit" onClick={handleShow}>Add Member</button>
                  </div>
                  <Modal
                    show={showModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Add Member</Modal.Title>
                      <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}><i className="fa fa-times"></i></button>
                    </Modal.Header>
                    <Modal.Body>
                      <form>
                        <div className="addrelation_form">
                          <label style={{ width: '150px', display: 'inline-block' }}>First Name:</label>
                          <input id="f_first_name" type="text" defaultValue="" />
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Last Name:</label>
                          <input id="f_last_name" type="text" defaultValue="" />
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Email:</label>
                          <input id="f_email" type="text" defaultValue="" />
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Mobile:</label>
                          <input id="f_mobile" type="number" defaultValue="" />
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Gender:</label>
                          <select value={f_s_gender} onChange={handleGenderChange}>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                          </select>
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Address:</label>
                          <input id="f_address" type="text" defaultValue="" />
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>Role:</label>
                          <select value={f_s_role} onChange={handleRoleChange}>
                            <option value={"User"}>User</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"Power User"}>Power User</option>
                            <option value={"Viewer"}>Viewer</option>
                            <option value={"Operator"}>Operator</option>
                            <option value={"In Active Role"}>In Active Role</option>
                          </select>
                          <br /><br />
                          <label style={{ width: '150px', display: 'inline-block' }}>User Name:</label>
                          <input id="f_user_name" type="text" defaultValue="" />
                          <br /><br />
                          <div className="dob-field">
                            <label style={{ width: '150px', display: 'inline-block' }}>DOB:</label>

                            {/* <DatePicker
                            id="f_dob"
                            selected={selectedDate}
                            // onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select a date"
                            className="readonly-datepicker"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          /> */}
                            <DatePicker
                              name="memberdob"
                              className="readonly-datepicker"
                              id="f_dob"
                              type="text"
                              size="sm"
                              placeholderText="select a date"
                              selected={selectedDate}
                              maxDate={new Date()}
                              value={setSelectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd-MM-yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />

                          </div>
                        </div>
                        <br />
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary"
                        onClick={add}>
                        Add New Member
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={isEditModalOpen} onHide={closeEditModal}>
                    <div className='edit_rel_lkp_model_container'>
                      <Modal.Header>
                        <Modal.Title>Edit Member</Modal.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={closeEditModal}>
                          <i className="fa fa-times"></i>
                        </button>
                      </Modal.Header>
                      <form>
                        {populateEditForm()}
                      </form>
                      <div className='edit_rel_lkp_model_footer'>
                        {/* <Modal.Footer>
                        <div className='edit_rel_lkp_model_btn'>
                          <Button id='rel_lkp_update_btn' variant="primary" onClick={updateData}>
                            Update
                          </Button>
                          <Button id='delete_member_btn' onClick={deletemember}>Delete</Button>
                        </div>
                      </Modal.Footer> */}
                      </div>
                    </div>
                  </Modal>
                  <br />
                </div>
                <Table columns={columns} data={data} />
              </Styles></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default App