import React from "react";
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import Chart from "chart.js/auto";
import { Line, Doughnut } from "react-chartjs-2";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { FaServer } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
/*
  This component's functonality is to display the content for the dashboard which includes
    * A graph and some information about the user such as their address and key.
    * A stats bar which can display
      * No of team members
      * No of customers
      * No of tickets raised by customers
      * No of Family members added by customers
    * A Actions Bar can display and has some functonality liske as follows
      * Add relation where total no of relaction codes need to be updated with relation name on tapping the add relation icon redirect to relation page
      * Delete Users will display the total no of users requested to delete their JB account on clicking delete user icon redirect to delete users page
      * Approve Invitations will display total no of invitations created by providers and ready for approval(under development)
    * Users count graph show thw no of users created JB account with respective to date
    * User Issues graph shows the status between solved and unsolved issues created by the users
    * User reports shows the reports submitted by the users
*/
class DashBoard extends React.Component {
  // checking the login status
  signincheck() {
    if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  }
  constructor(props) {
    super(props);
    this.changetempStatus = this.changetempStatus.bind(this);
    // declaring the state variables
    this.state = {
      apiResponse: "",
      userlogin: null,
      maintenancestat: null,
      users_count: '',
      user_issue_count: '',
      team_count: '',
      action_rel_code_count: '',
      action_deleted_user_count: '',
      user_role_acc: window.localStorage.getItem("role"),
      members_count: '',
      user_reports: [],
      user_count_graph: [{
        count: [],
        year: [],
        month: [],
      }],
      userdata: [],
      data: {
        labels: [],
        datasets: [
          {
            label: false,
            backgroundColor: "#63b0db60",
            borderColor: "#026fbf90",
            width: '100%',
            fill: false,
            data: [],
          },
        ],
      },
      user_issue_status: [],
      issuesdata: {
        labels: [],
        datasets: [
          {
            label: "User Issues",
            backgroundColor: "#63b0db60",
            borderColor: "#026fbf90",
            width: '100%',
            fill: true,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        },
      },
    };
  }
  // signincheck() {
  //   if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  // }

  componentDidMount() {
    //   fetch(globaladdress+"getproviderapprovallist?key="+globalkey)
    //  .then((res) => res.json())
    //  .then((json) => {
    //      this.setState({
    //          items: json,
    //          DataisLoaded: true
    //      });
    //  })

    // making required fetch calls for the dashboard to display the data
    fetch(globaladdress + "getproviderapprovallist?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const json = JSON.parse(resText);
          this.setState({
            items: json,
            DataisLoaded: true
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
    fetch(globaladdress + "getactioncount?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);

          this.setState({
            action_rel_code_count: res.action_rel_code_count,
            action_deleted_user_count: res.action_deleted_user_count
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
    fetch(globaladdress + "getuserwacount?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            team_count: res.team_count,
            users_count: res.users_count,
            user_issue_count: res.user_issues_count,
            count: res.user_count_graph.count,
            year: res.user_count_graph.year,
            month: res.user_count_graph.month,
            members_count: res.member_count,
            user_reports: res.user_reports
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);

      });
    fetch(globaladdress + "getmaintenancestatus?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            maintenancestat: res,
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);

      });


    fetch(globaladdress + "getmonthlyusersdata?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          var counts = [0];
          var labels = ["03/2023"];
          var count = 0;
          var currlabel = "04/2023"
          res.forEach(e => {
            var date = e.registred_date.split('-');
            var label = date[1] + "/" + date[0];
            count = count + 1;
            if (label !== currlabel) {
              labels.push(currlabel);
              currlabel = label;
              counts.push(count);
            }
          }
          );
          labels.push(currlabel);
          counts.push(count);
          this.setState({
            DataisLoaded: true,
            userdata: res,
            data: {
              labels: labels,
              datasets: [
                {
                  label: false,
                  backgroundColor: "#63b0db60",
                  borderColor: "#026fbf90",
                  width: '100%',
                  fill: false,
                  data: counts,
                },
              ],
            }
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
    // .then((res) => res.json())
    // .then((res) => {
    //   var counts = [0];
    //   var labels = ["03/2023"];
    //   var count = 0;
    //   var currlabel = "04/2023"
    //   res.forEach(e => {
    //     var date = e.registred_date.split('-');
    //     var label = date[1] + "/" + date[0];
    //     count = count + 1;
    //     if (label !== currlabel) {
    //       labels.push(currlabel);
    //       currlabel = label;
    //       counts.push(count);
    //     }
    //   }
    //   );
    //   labels.push(currlabel);
    //   counts.push(count);
    //   this.setState({
    //     DataisLoaded: true,
    //     userdata: res,
    //     data: {
    //       labels: labels,
    //       datasets: [
    //         {
    //           label: false,
    //           backgroundColor: "#63b0db60",
    //           borderColor: "#026fbf90",
    //           width: '100%',
    //           fill: false,
    //           data: counts,
    //         },
    //       ],
    //     }
    //   });
    // })
    // .catch((error) => {
    //   console.error("Fetch error:", error);

    // })
    fetch(globaladdress + "getuserissuestatus?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        console.log("Full response:", resText);
        if (resText === "Session timeOut!") {
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          const counts = res.map((item) => item.count);
          const statusCategories = res.map((item) => item.status_category);
          this.setState({
            user_issue_status: res,
            issuesdata: {
              ...this.state.issuesdata,
              labels: statusCategories,
              datasets: [
                {
                  ...this.state.issuesdata.datasets[0],
                  data: counts,
                },
              ],
            },
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

    // .catch((error) => {
    //   console.error("Fetch error:", error);
    // })
  }
  // function to handle the maintenance status and maintenance toogle switch functonality
  changetempStatus() {
    var checkbox = document.getElementById("isEnabled");
    // this.setState({ isbold: checkbox.checked })
    // var sts;
    // checkbox.checked === true ? sts = "true" : sts = "false"
    // this.state.maintenancestat = sts;
    const isChecked = checkbox.checked;
    const sts = isChecked ? "true" : "false";

    this.setState({ maintenancestat: sts })
    try {
      axios.post(globaladdress + "updateservermaintenance?maintenance=" + sts + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            // Continue with the reload or other logic
            window.location.reload();
          }
        }).catch((error) => {
          console.error("Fetch error:", error);
        });
      // .then(() => { window.location.reload() })
      //  alert("function called in try bloack");
    } catch {
      toast.error("api call skipped!!")
    }
  }
  sendNextDayEventsNotifications() {
    try {
      alert("button clicked");
      axios.get(globaladdress + 'sendeventnotificationfornextday?key=' + globalkey)
        .then(() => {
          alert("notifications sent");
        })
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    // setting up the const values
    const buttonText = this.state.maintenancestat === "true" ? "Enabled" : "Disabled";

    return (
      // actual page to render
      <div className="container body">
        <div className='main_container'>
          <div className='col-md-3 left_col'>
            <Navbar />
          </div>
          <Header />
          <div className='right_col'>
            <div className='container2' style={{ backgroundColor: '#eee ' }}>
              <div className='content'>
                <div className='maincontent col-md-8 col-sm-10'>
                  <div id="maintstat">
                    <div className="m_n_btn_container">
                      <div className="toggle_container">
                        <div className="label_content">
                          <h3 style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "nowrap", alignContent: "center" }}><FaServer />&nbsp;Maintenance:&nbsp;{buttonText}</h3>

                          {/* <div>
                          <button onClick={toogletoast}>Notify!</button>
                          <ToastContainer />
                        </div> */}
                        </div>
                        {/* {this.state.maintenancestat === "true" && <div className="toggle-switch">
                        <input
                          type="checkbox"
                          className="checkbox"
                          name='isEnabled'
                          id='isEnabled'
                          checked={true}
                          onChange={this.changetempStatus}
                        />
                        <label className="label" htmlFor="isEnabled">
                          <span className="inner" />
                          <span className="switch" />
                        </label>
                      </div>} */}
                        {/* {this.state.maintenancestat === "false" && <div className="toggle-switch">
                        <input
                          type="checkbox"
                          className="checkbox"
                          name='isEnabled'
                          id='isEnabled'
                          onChange={this.changetempStatus}
                        />
                        <label className="label" htmlFor="isEnabled">
                          <span className="inner" />
                          <span className="switch" />
                        </label>
                      </div>} */}
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name="isEnabled"
                            id="isEnabled"
                            checked={this.state.maintenancestat === "true"}
                            onChange={this.changetempStatus}
                          />
                          <label className="label" htmlFor="isEnabled">
                            <span className="inner"></span>
                            <span className="switch"></span>
                          </label>
                        </div>
                        {/* </div> */}
                      </div>
                      <div>
                        {buttonText === "Enabled" && <div>
                          <p>server under maintenance</p>
                        </div>}
                      </div>
                      <div className="send_event_notifications_btn">
                        <button
                          className="send_eve_notif"
                          onClick={this.sendNextDayEventsNotifications}>
                          Send Event Notifications
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className='tabs'>
                    <h2>Stats</h2>
                    <div className='counttab combo4'>
                      <i className="fa fa-university"></i>
                      <h3 className='count'>{this.state.team_count}</h3>
                      <h5>Members</h5>
                      <p> Our technical support team</p>
                    </div>
                    <div className='counttab combo3'>
                      <i className="fa fa-users"></i>
                      <h3 className='count'>{this.state.users_count}</h3>
                      <h5>Users</h5>
                      <p> Customers using JB App</p>
                    </div>
                    <div className='counttab combo1'>
                      <i className="fa fa-ticket"></i>
                      <h3 className='count'>{this.state.user_issue_count}</h3>
                      <h5>Tickets</h5>
                      <p>User-reported issues on JB App</p>
                    </div>
                    <div className='counttab combo2'>
                      <i className="fa fa-heart"></i>
                      <h3 className='count'>{this.state.members_count}</h3>
                      <h5>Family </h5>
                      <p> People added on JB App</p>
                    </div>
                  </div>
                  <div className="user-actions">
                    <h2>Actions</h2>
                    {this.state.action_rel_code_count > 0 ? (
                      <div className="action-div">
                        <a href="/addrelations">
                          <i className="fa fa-snowflake-o"></i>
                        </a>
                        <p>Add relations</p>
                        {this.state.action_rel_code_count > 0 &&
                          <div>
                            <span className="attention">{this.state.action_rel_code_count}</span>
                          </div>
                        }
                      </div>
                    ) : <div className="action-div" onClick={() => toast.info("There are no new relation codes to add", {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    })}>
                      <ToastContainer />
                      <i className="fa fa-snowflake-o"></i>
                      <p>Add relations</p>
                      {this.state.action_rel_code_count > 0 &&
                        <div>
                          <span className="attention">
                            {this.state.action_rel_code_count}</span>
                        </div>
                      }
                    </div>
                    }
                    {this.state.action_deleted_user_count > 0 ? (
                      <div className="action-div">
                        <a href="/deletedUsers">
                          <i className="fa fa-user-times"></i>
                        </a>
                        <p>Delete Users</p>
                        {this.state.action_deleted_user_count > 0 && (
                          <div>
                            <span className="attention">
                              {this.state.action_deleted_user_count}</span>
                          </div>
                        )}
                      </div>
                    ) :
                      <div className="action-div" onClick={() => toast.info("There are no users to delete", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      })}>
                        <i className="fa fa-user-times"></i>
                        <p>Delete User</p>
                        {this.state.action_deleted_user_count > 0 && (
                          <div>
                            <span className="attention">{this.state.action_deleted_user_count}</span>
                          </div>
                        )}
                      </div>
                    }
                    <div className="action-div" onClick={() => toast.warning(" Approve Invitation's is under construction", {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",

                    })}>
                      <i className="fa fa-address-book-o"></i>
                      <p>Approve Invitation</p>
                      {/* <span className="attention">1</span> */}

                    </div>
                  </div>
                  <div className='feedback'>
                    <h2>Users Count</h2>
                    <hr className='hr' />
                    <div className="graph">
                      <Line
                        id="mychart"
                        data={this.state.data}
                        options={this.state.options}
                      />
                    </div>
                  </div>
                </div>
                <div className='sidecontent col-md-4 col-sm-12'>
                  <div className='sidecontentbox'>
                    <h4>Users Issues</h4>
                    <hr className='hr' />
                    <div className="dgraph">
                      <Doughnut
                        id="mychart"
                        data={this.state.issuesdata}
                        options={this.state.options}
                      />
                    </div>
                  </div>
                  <div className='sidecontentbox'>
                    <h4>Users Reports</h4>
                    <hr className='hr' />
                    <div className="sidecontentbox_content">
                      {this.state.user_reports.length >= 1 ? (
                        this.state.user_reports.map((ur, index) => (
                          <div key={index}>
                            <ul key={index}>
                              <div className="under_list_user_reports">
                                <li id="list_items_user_reports">
                                  <h4>
                                    User Id: {ur.userId}<br />
                                    Reported Id: {ur.reportedId}<br />
                                    Reason: {ur.reason}<br />
                                    Report Status: {ur.status}<br />
                                    length : {this.state.user_reports.length}
                                  </h4>
                                </li>
                              </div>
                            </ul>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>The user reports are empty.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default DashBoard;


