import React from 'react';
import Navbar from "../components/Navbar";
import Header from '../components/Header/header';

import "../App.css";
// import axios from 'axios';
function Content() {
  return (
    <div className='full_page'>
      <div className='col-md-2 side-nav'>
        <Navbar />
      </div>
      <div className='col-md-10 page-content'>
        <div className='container2'>
          <div className='content'>
            <h1>Events Page</h1>
            <div className='card_div'>
              <div className='cards'>
                <h1>card content</h1>
              </div>
              <div className='cards' id='card2'>
                <h1>card content</h1>
              </div>
              <div className='cards' id='card2'>
                <h1>card content</h1>
              </div>
              <div className='cards' id='card2'>
                <h1>card content</h1>
              </div> <div className='cards' id='card2'>
                <h1>card content</h1>
              </div>
              <div className='cards' id='card2'>
                <h1>card content</h1>
              </div>
              <div className='cards' id='card2'>
                <h1>card content</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;