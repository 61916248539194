import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactLoading from "react-loading";
import Navbar from "../../components/Navbar";
import Header from '../../components/Header/header';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import LogoWhite from '../assets/images/logo5.svg'
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FaPlus, FaEdit, FaBars, } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import "./CSS/tags.css"

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_add_question_form: false,
            questionType: '',
            options: [],
            newOption: '',
            isEditModalOpen: false,
            editedTag: null,
            editedValue: '',
            editedLabel: '',
            templateTags: [],
            selectedTags: [],
            user_role_acc: window.localStorage.getItem("role"),
            searchQuery: '',

        }
        this.handleUpdateTag = this.handleUpdateTag.bind(this);
    };
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    roleckeck() {
        if (this.state.user_role_acc === "User") {
            document.querySelector("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Viewer") {
            document.getElementById("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Operator") {
            document.getElementById("").style.display = "none";
        }
        if (this.state.user_role_acc === "In Active Role") {
            document.getElementById("").style.display = "none";
        }
    }
    componentDidMount() {
        this.signincheck();
        this.roleckeck();
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "gettemplatetags?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTags: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });

    }
    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    }
    toogleAddQuestion = () => {
        this.setState({
            show_add_question_form: !this.state.show_add_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
        });
    }
    handleOptionChange = (e) => {
        this.setState({ newOption: e.target.value });
    };
    getSuggestedTags = () => {
        const { newOption, templateTags } = this.state;
        return templateTags.filter(tag => tag.label.includes(newOption));
    };
    addOption = () => {
        const { newOption, options } = this.state;
        if (newOption.trim() !== '') {
            this.setState({
                options: [...options, newOption],
                newOption: '',
            });
        }
    };
    addQuestion(event) {
        event.preventDefault();
        const newOption = this.state.newOption;
        const updatedOptions = [...this.state.options, newOption];
        const nonEmptyOptions = updatedOptions.filter(option => option.trim() !== "");
        const serializedOptions = nonEmptyOptions.map(option => `"${option.trim()}"`).join(',');

        this.setState({
            show_question: !this.state.show_question,
            options: updatedOptions,
            newOption: '',
        });

        var dataset = {
            tag: serializedOptions,
        };
        try {
            const queryString = Object.keys(dataset)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dataset[key]))
                .join('&');
            axios.post(`${globaladdress}addtemplatetags?${queryString}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("Tags added successfully.");
                            window.location.reload();
                        }
                    } else {
                        alert(`Internal server error \n Error Status code: ${response.status}`);
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong while adding a new Tag!\nPlease try again later.");
        }
    }

    openEditModal = (value, label) => {
        this.setState({
            isEditModalOpen: true,
            editedTag: { value, label },
            editedValue: value,
            editedLabel: label
        });
    };

    handleCloseModal = (event) => {
        this.setState({ isEditModalOpen: false });
        window.location.reload();
    };


    handleUpdateTag() {
        const { editedValue, editedLabel, templateTags } = this.state;



        this.handleCloseModal();

        axios.delete(`${globaladdress}deletetags?value=${editedValue}&label=${editedLabel}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
            .then((res) => {
                const resText = res.data;
                console.log("Full response:", resText);
                if (resText === "Session timeOut!") {
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else if (resText === "Tag updated!") {
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleLabelChange = (e) => {
        const newLabel = e.target.value;
        const isLabelExists = this.state.templateTags.some(tag => tag.label === newLabel);

        this.setState({
            editedLabel: newLabel,
            isLabelExists: isLabelExists
        });
    };
    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value });
    };
    render() {

        const { show_add_question_form, newOption } = this.state;
        const suggestedTags = this.getSuggestedTags();
        const isSubmitDisabled = suggestedTags.length > 0;
        const { templateTags, searchQuery } = this.state
        const { isEditModalOpen, editedLabel } = this.state;
        const isLabelExists = templateTags.some(tag => tag.label === editedLabel);
        const filteredTags = templateTags.filter(tag =>
            tag.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return (
            <>
                <div className="container body">
                    <div className='main_container'>
                        <div className='col-md-3 left_col'>
                            <Navbar username={localStorage.username} />
                        </div>
                        <Header />
                        <div className='right_col'>
                            <div className='container2'>
                                <div className='content'>
                                    <div className='topContainer'>
                                        <button className='add_questions-button' onClick={this.toogleAddQuestion}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <FaPlus style={{ background: '#fff', color: '026FBF', fontSize: '20px', borderRadius: '15px', padding: '5px' }} />
                                                <span style={{ marginLeft: '5px' }}>Add Tags</span>
                                            </div>
                                        </button>
                                        <div className='searchTags_container'>
                                            <input
                                                className='searchTags_container_inputField'
                                                type="text"
                                                value={searchQuery}
                                                onChange={this.handleSearchChange}
                                                placeholder="Search tags..."
                                            />
                                        </div>
                                    </div>
                                    <hr className='hr_line' style={{ borderColor: "#878787" }}></hr>
                                    <div className="TagsList">
                                        {/* Render filtered tags */}
                                        {filteredTags.map((tag, index) => (

                                            <div key={index} className="tagItem" onClick={() => this.openEditModal(tag.value, tag.label)}>
                                                #{tag.label}
                                                <MdClose />
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <div className='survey_page_add_question_form_container'>
                                            {show_add_question_form && (
                                                <div className="edit_app_notes">
                                                    <div className="add_tags_inner">
                                                        <h2>Create Tags</h2>
                                                        <br></br>
                                                        <form onSubmit={this.addQuestion}>
                                                            <div>
                                                                <input
                                                                    className="add_tag_input_field"
                                                                    placeholder='Enter tag'
                                                                    value={newOption}
                                                                    onChange={this.handleOptionChange}
                                                                />
                                                                <br />
                                                                <br />
                                                                <div>
                                                                    <div className='add_question_label'>
                                                                        <ul className="tag-grid">
                                                                            {suggestedTags.map(tag => (
                                                                                <li className="tag_lables" key={tag.value}>#{tag.label}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className='editreleasenotespopupform'>
                                                                <button type="submit" disabled={isSubmitDisabled}>Submit</button>
                                                                <button className="close-btn" onClick={this.toogleAddQuestion} style={{ float: 'right' }}>
                                                                    Close form
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Modal show={isEditModalOpen} onHide={this.handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Tag</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <p>You are about to delete "{editedLabel}"" tag</p>

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleCloseModal}>
                                                Close
                                            </Button>
                                            <Button variant="danger" onClick={this.handleUpdateTag} >
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Tags;