import React, { Component } from "react";
import ReactLoading from "react-loading";
import Navbar from "../../../components/Navbar";
import Header from '../../../components/Header/header';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import LogoWhite from '../../assets/images/logo5.svg'
import { globalkey, globaladdress, s_key, s_secret } from '../../variables'
import { toast } from 'react-toastify';
import LayerDetails from "./InvitationLayerComponent";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FaPlus, FaEdit, FaBars, } from "react-icons/fa";

import { error } from "jquery";
/*

*/
class Invitations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            searchText: '',
            DataisLoaded: false,
            showModal: false,
            showModal2: false,

            show_add_question_form: false,
            questionType: '',
            options: [],
            newOption: '',

            image: null,
            imageFile: null,
            finalprice: null,
            fontList: [],
            categories: [],
            templateTags: [],
            selectedTags: [],
            finishedimage: null,
            finishedimagefile: null,
            templates: [],
            selectedTempIndex: null,
            user_role_acc: window.localStorage.getItem("role"),
            selectedTemplate: {
                user_role_acc: window.localStorage.getItem("role"),
                templateid: null,
                templatename: null,
                status: null,
                //back:null,
                full: null,
                category: null,
                font: null,
                text_length: null,
                price: null,
                userid: null,
                description: null,
            },
            isenablestempl: null,
            editprice: false,
            newprice: null,
            isbold: null,
            currentPage: 1,
            itemsPerPage: 20,
            selectedTemplate: {
                user_role_acc: null,
                templateid: null,
                templatename: null,
                status: null,
                full: null,
                category: null,
                font: null,
                price: null,
                text_length: null,
                text_width: null,
                userid: null,
                description: null,
            },

        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow2 = this.handleShow2.bind(this);
        this.handleClose2 = this.handleClose2.bind(this);
        this.inputcheck = this.inputcheck.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImage2 = this.uploadImage2.bind(this);
        this.submitInvitation = this.submitInvitation.bind(this);
        this.validitycheck = this.validitycheck.bind(this);
        this.selectInvitaion = this.selectInvitaion.bind(this);
        this.pricechange = this.pricechange.bind(this);
        this.getTotalPages = this.getTotalPages.bind(this);
        this.addQuestion = this.addQuestion.bind(this);

    }
    handleInputChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };
    handleSearch = (event) => {
        this.setState({ searchText: event.target.value, currentPage: 1 });

    };
    getTotalPages() {
        const { templates, itemsPerPage, searchText } = this.state;
        const filteredList = templates.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.category.toLowerCase().includes(searchText.toLowerCase()) ||
                item.price.toLowerCase().includes(searchText.toLowerCase()) ||
                this.checkTags(item.label, searchText));
        return Math.ceil(filteredList.length / itemsPerPage);
    }

    checkTags(label, searchText) {
        const tags = label.map(tag => tag.label); // Extracting labels from objects
        const regex = new RegExp(searchText.toLowerCase(), 'i');
        return tags.some(tag => regex.test(tag.toLowerCase()));
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };
    handlePrevPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: currentPage - 1 });
        }
    };
    handleNextPage = () => {
        const { currentPage } = this.state;
        const totalPages = this.getTotalPages();
        if (currentPage < totalPages) {
            this.setState({ currentPage: currentPage + 1 });
        }
    };
    validitycheck() {
        var count = 0;
        var inputlist = document.getElementsByClassName('special-input');
        for (var i = 0; i < inputlist.length; i++) {
            var alertmsg = inputlist[i].parentNode.querySelector('.validation-alert');
            if (!inputlist[i].classList.contains('hastext')) {
                if (!alertmsg.classList.contains('invalid')) alertmsg.classList.add('invalid');
                count = count + 1;
            }
            else {
                if (alertmsg.classList.contains('invalid')) alertmsg.classList.remove('invalid');
            };
        }
        if (!this.state.image) {
            document.getElementById('back_image_valid').classList.add('invalid');
            count = count + 1
        } else {
            document.getElementById('back_image_valid').classList.remove('invalid');
        }
        if (!this.state.finishedimage) {
            document.getElementById('full_image_valid').classList.add('invalid');
            count = count + 1;
        } else {
            document.getElementById('full_image_valid').classList.remove('invalid')
        }
        if (count === 0) {
            toast(true);
            return true;
        } else {
            return false;
        }
    }
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    roleckeck() {
        if (this.state.user_role_acc === "User") {
            document.querySelector("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Viewer") {
            document.getElementById("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Operator") {
            document.getElementById("").style.display = "none";
        }
        if (this.state.user_role_acc === "In Active Role") {
            document.getElementById("").style.display = "none";
        }
    }
    componentDidMount() {
        this.signincheck();
        this.roleckeck();
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "gettemplates?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        DataisLoaded: true,
                        templates: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "getfonts?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        fontList: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "getcatlkp?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        categories: res,
                    });
                }

            }).catch((error) => {
                alert('Error in fetching data from the server');
            });
        fetch(globaladdress + "gettemplatetags?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTags: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
    }
    handleClose() { this.setState({ showModal: false }) }
    handleShow() { this.setState({ showModal: true }) }
    handleClose2() { this.setState({ showModal2: false }) }
    handleShow2() { this.setState({ showModal2: true }) }
    pricechange(e) {
        this.inputcheck(e);
        var price = document.getElementById("invit_price").value;
        var discount = document.getElementById("invit_discount").value;
        if (discount <= 100 && discount >= 0 && price) {
            var finalprice = parseInt(price) * (100 - parseInt(discount)) / 100;
            this.setState({
                finalprice: finalprice
            })
        }
    }
    inputcheck(e) {
        var el = e.currentTarget;
        var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
        if (el.value !== '' && !el.classList.contains('hastext')) {
            el.classList.add('hastext')
            if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
        } else if (el.value === '' && el.classList.contains('hastext')) {
            el.classList.remove('hastext')
            alertmsg.add('invalid')
        }
    }
    uploadImage2(e) {
        var objurl = URL.createObjectURL(e.target.files[0]);
        this.setState({ finishedimage: objurl, finishedimagefile: e.target.files[0] });
        var alertmsg = document.getElementById('full_image_valid').classList;
        if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    }
    uploadImage(e) {
        var objurl = URL.createObjectURL(e.target.files[0]);
        this.setState({ image: objurl, imageFile: e.target.files[0] });
        var alertmsg = document.getElementById('back_image_valid').classList;
        if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    }
    submitInvitation() {
        var bool = this.validitycheck();
        if (bool) {
            var title = document.getElementById("invit_title").value;
            var description = document.getElementById("invit_desc").value;
            var price = document.getElementById("invit_price").value;
            var discount = document.getElementById("invit_discount").value;
            var category = document.getElementById("invit_cat").value;
            var font = document.getElementById("invit_font").value;
            var selectedTags = this.state.selectedTags;
            var selTags = selectedTags.map(tag => tag.value).join(',');
            var formdata = new FormData();
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("price", price);
            formdata.append("offer_percentage", discount);
            formdata.append("offer_price", this.state.finalprice);
            formdata.append("category", category);
            formdata.append("font", font);
            formdata.append("userid", window.localStorage.getItem("userid"));
            formdata.append("back_image", this.state.imageFile);
            formdata.append("display", this.state.finishedimagefile);
            formdata.append(`tag`, selTags);
            axios.post(globaladdress + 'addtemplate?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.text();
                })
                .then((resText) => {
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload();

                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    selectInvitaion(e) {
        var unselect = document.getElementsByClassName('selected');
        if (unselect.length !== 0) unselect[0].classList.remove('selected');
        e.currentTarget.classList.add('selected');
        var tid = e.currentTarget.dataset.templid;
        var index = this.state.templates.findIndex(x => x.tId == tid);
        var tempstatus = this.state.templates[index].status
        const seltemp = {
            templateid: this.state.templates[index].tId,
            templatename: this.state.templates[index].title,
            status: this.state.templates[index].status,
            full: this.state.templates[index].finished_image,
            category: this.state.templates[index].category,
            font: this.state.templates[index].font,
            text_length: this.state.templates[index].text_length,
            price: this.state.templates[index].price,
            userid: this.state.templates[index].team_mem_id,
            description: this.state.templates[index].description,
        }
        this.setState({ selectedTemplate: seltemp, selectedTempIndex: tid, showModal2: true })
    }
    updateSelectedTemplate = (updatedTemplate) => {
        this.setState({
            selectedTemplate: updatedTemplate,
        });
    };

    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    }
    toogleAddQuestion = () => {
        this.setState({
            show_add_question_form: !this.state.show_add_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
        });
    }
    handleOptionChange = (e) => {
        this.setState({ newOption: e.target.value });
    };
    getSuggestedTags = () => {
        const { newOption, templateTags } = this.state;
        return templateTags.filter(tag => tag.label.includes(newOption));
    };
    addOption = () => {
        const { newOption, options } = this.state;
        if (newOption.trim() !== '') {
            this.setState({
                options: [...options, newOption],
                newOption: '',
            });
        }
    };
    addQuestion(event) {
        event.preventDefault();
        const newOption = this.state.newOption;
        const updatedOptions = [...this.state.options, newOption];

        // Filter out empty options
        const nonEmptyOptions = updatedOptions.filter(option => option.trim() !== "");

        // Serialize options array
        const serializedOptions = nonEmptyOptions.map(option => `"${option.trim()}"`).join(',');

        this.setState({
            show_question: !this.state.show_question,
            options: updatedOptions,
            newOption: '',
        });

        var dataset = {
            tag: serializedOptions,
        };
        try {
            const queryString = Object.keys(dataset)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dataset[key]))
                .join('&');
            axios.post(`${globaladdress}addtemplatetags?${queryString}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("Tags added successfully.");
                            window.location.reload();
                        }
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong while adding a new Tag!\nPlease try again later.");
        }
    }
    handleQuestionTypeChange = (e) => {
        this.setState({
            questionType: e.target.value,
            options: e.target.value === 'options' ? [] : this.state.options,
        });
    };
    render() {
        const {
            templates,
            searchText,
            DataisLoaded,
            currentPage,
            itemsPerPage,
        } = this.state;
        const filteredList = templates.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.category.toLowerCase().includes(searchText.toLowerCase()) ||
                item.price.toLowerCase().includes(searchText.toLowerCase()) ||
                this.checkTags(item.label, searchText)

        );

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredList.slice(
            indexOfFirstItem,
            indexOfLastItem
        );
        const tagOptions = this.state.templateTags.map(item => ({ value: item.value, label: item.label }));
        const { show_add_question_form, questionType, options, newOption } = this.state;
        const suggestedTags = this.getSuggestedTags();
        const isSubmitDisabled = suggestedTags.length > 0;

        return (
            <>
                <div className="container body">
                    <div className='main_container'>
                        <div className='col-md-3 left_col'>
                            <Navbar username={localStorage.username} />
                        </div>
                        <Header />
                        <div className='right_col'>
                            <div className='container2'>
                                <div className='content'>
                                    <h1>Invitation Templates</h1>
                                    <div className="button-to-right" id="create_template_btn">
                                        <button
                                            className="btn btn-primary"
                                            variant="primary"
                                            onClick={this.handleShow}
                                        >
                                            <i className="fa fa-plus"> Create Template</i>
                                        </button>
                                    </div>
                                    <button className='add_questions-button' onClick={this.toogleAddQuestion}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <FaPlus style={{ background: '#fff', color: '026FBF', fontSize: '20px', borderRadius: '15px', padding: '5px' }} />
                                            <span style={{ marginLeft: '5px' }}>Add Tags</span>
                                        </div>
                                    </button>
                                    <hr />
                                    <div className="main-content">
                                        <div className="">
                                            <input
                                                id="templates_search_box"
                                                type="text"
                                                placeholder="Search templates..."
                                                onChange={this.handleSearch}
                                                value={searchText}
                                            />
                                            <div className="pagination invitation-pagination">
                                                <button
                                                    onClick={this.handlePrevPage}
                                                    disabled={currentPage === 1}
                                                    className="invitations_pageination_btns"
                                                >
                                                    <i className="fa fa-arrow-left"></i>
                                                </button>
                                                <span>
                                                    {`Showing ${(currentPage - 1) * 20 + 1}- ${templates.length > (currentPage) * 20 ? (currentPage) * 20 : templates.length} of ${templates.length} users (Page ${currentPage} of ${this.getTotalPages()})`}
                                                </span>
                                                <button
                                                    onClick={this.handleNextPage}
                                                    disabled={currentPage === this.getTotalPages()}
                                                    style={{ marginLeft: "15px" }}
                                                    className="invitations_pageination_btns"
                                                >
                                                    <i className="fa fa-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div className="line-seperator"></div>
                                            <div className="template-list">
                                                {!DataisLoaded && (
                                                    <div className="loader-back">
                                                        <div className="loader-element">
                                                            <img
                                                                className="loader-svg"
                                                                src={LogoWhite}
                                                                alt='Jagbandhu'
                                                            />
                                                            <ReactLoading
                                                                type="spinningBubbles"
                                                                color="#FFFFFF"
                                                                height={300}
                                                                width={150}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {DataisLoaded && templates && currentItems.length > 0 && currentItems.map((templ, index) => (
                                                    <a
                                                        key={templ.tId}
                                                        data-idx={index}
                                                        data-templid={templ.tId}
                                                        onClick={this.selectInvitaion}
                                                        className="invitation_card"
                                                    >
                                                        <div className="templ_image_container">
                                                            <img
                                                                className="templ_image"
                                                                src={"data:image/png;base64," + templ.finished_image}
                                                                alt="Invitaion preview"
                                                            />
                                                        </div>
                                                        <div className="templ_details">
                                                            <h3 className="templ_title">{templ.title}</h3>
                                                            <div className="templ_pricepane">
                                                                <div className="catandfont">
                                                                    <h6 className="templ_category">{templ.category}</h6>
                                                                    <h6 className="templ_font">{templ.font}</h6>
                                                                </div>
                                                                <div className="price">
                                                                    <h3 style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "nowrap", alignContent: "center" }}>
                                                                        <i className="fa fa-rupee"></i>&nbsp;
                                                                        {templ.price}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                ))}
                                                {DataisLoaded && templates && currentItems.length === 0 && <div><p>No Templates to select</p></div>}
                                            </div>
                                        </div>
                                        <div className="vertical-border"></div>
                                    </div>
                                    <div className="Update_layers_modal" id="Update_layers_modal_id">
                                        <Modal
                                            dialogClassName="template_det_modal"
                                            show={this.state.showModal2}
                                            onHide={this.handleClose2}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Template Details</Modal.Title>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    aria-label="Close"
                                                    onClick={this.handleClose2}
                                                >
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {this.state.selectedTempIndex &&
                                                    <LayerDetails
                                                        template={this.state.selectedTemplate}
                                                        updateSelectedTemplate={this.updateSelectedTemplate}
                                                        updateTemplatePrice={this.updateTemplatePrice}
                                                        handleClose2={this.handleClose2}
                                                    />
                                                }
                                                {!this.state.selectedTempIndex && <p>No Template Selected</p>}
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <Modal
                                        show={this.state.showModal}
                                        onHide={this.handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header>
                                            <Modal.Title>Create New Invitation</Modal.Title>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                aria-label="Close"
                                                onClick={this.handleClose}
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="col-md-12">
                                                <div className="invitation-form">
                                                    <div className="form-field">
                                                        <input
                                                            id="invit_title"
                                                            className="special-input"
                                                            onChange={this.inputcheck}
                                                        />
                                                        <label
                                                            className="special-lable"
                                                            id="special-lable_title"
                                                        >
                                                            Title *
                                                        </label>
                                                        <span
                                                            className="validation-alert"
                                                        >
                                                            Title cannot be empty
                                                        </span>
                                                    </div>
                                                    <div className="form-field">
                                                        <textarea
                                                            id="invit_desc"
                                                            className="special-input"
                                                            onChange={this.inputcheck}
                                                        >
                                                        </textarea>
                                                        <label
                                                            className="special-lable"
                                                            id="special-lable_description"
                                                        >
                                                            Description *
                                                        </label>
                                                        <span
                                                            className="validation-alert"
                                                        >
                                                            Description cannot be empty
                                                        </span>
                                                    </div>
                                                    <div className="priceRow">
                                                        <div className="form-field col-md-4">
                                                            <input
                                                                type="number"
                                                                max-length="3"
                                                                id="invit_price"
                                                                className="special-input"
                                                                onChange={this.pricechange}
                                                            />
                                                            <label
                                                                className="special-lable"
                                                                id="special-lable_price"
                                                            >
                                                                Price *
                                                            </label>
                                                            <span
                                                                className="validation-alert"
                                                            >
                                                                Price cannot be empty
                                                            </span>
                                                        </div>
                                                        <div className="form-field col-md-4">
                                                            <input
                                                                type="number"
                                                                max-length="2"
                                                                min={0}
                                                                max={100}
                                                                id="invit_discount"
                                                                className="special-input"
                                                                onChange={this.pricechange}
                                                            />
                                                            <label
                                                                className="special-lable"
                                                                id="special-lable_discount"
                                                            >
                                                                Discount*
                                                            </label>
                                                            <span
                                                                className="validation-alert"
                                                            >
                                                                Price cannot be empty
                                                            </span>
                                                        </div>
                                                        <div className="form-field col-md-4">
                                                            <p>Final Price: {this.state.finalprice}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-field">
                                                        <select
                                                            id="invit_cat"
                                                            className="special-input"
                                                            onChange={this.inputcheck}
                                                        >
                                                            <option>Select</option>
                                                            {this.state.categories.map((cat) => {
                                                                return <option
                                                                    key={cat.clkpId}
                                                                    value={cat.categoryName}
                                                                >
                                                                    {cat.categoryName}
                                                                </option>;
                                                            })}
                                                        </select>
                                                        <label
                                                            className="special-lable"
                                                        >
                                                            Category *
                                                        </label>
                                                        <span
                                                            className="validation-alert"
                                                        >
                                                            Please select a category
                                                        </span>
                                                    </div>
                                                    <div className="form-field">
                                                        <select
                                                            id="invit_font"
                                                            className="special-input"
                                                            onChange={this.inputcheck}
                                                        >
                                                            <option >Select</option>
                                                            {this.state.fontList.map((font) => {
                                                                return <option
                                                                    key={font.id}
                                                                    value={font.f_name}
                                                                >
                                                                    {font.f_name}
                                                                </option>;
                                                            })}
                                                        </select>
                                                        <label
                                                            className="special-lable"
                                                        >
                                                            Font *
                                                        </label>
                                                        <span
                                                            className="validation-alert"
                                                        >
                                                            Please select a font
                                                        </span>
                                                    </div>
                                                    <div className="choose_tags">
                                                        <Select
                                                            defaultValue={[]}
                                                            isMulti
                                                            name="tags"
                                                            options={tagOptions}
                                                            className="basic-multi-select"
                                                            id="selected-tags"
                                                            classNamePrefix="select"
                                                            placeholder="Select tags ..."
                                                            onChange={this.handleTagChange}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    <div className="form-field">
                                                        <label
                                                            className="image-lable"
                                                        >
                                                            Choose Background Image
                                                            <input
                                                                id="invit_image"
                                                                className="image-input"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={this.uploadImage}
                                                            />
                                                        </label>
                                                        <img
                                                            className="uploaded-image-preview"
                                                            src={this.state.image}
                                                            alt="Nothing Selected"
                                                        />
                                                        <span
                                                            id="back_image_valid"
                                                            className="validation-alert"
                                                        >
                                                            Please select a backgound Image
                                                        </span>
                                                    </div>
                                                    <div className="form-field">
                                                        <label
                                                            className="image-lable"
                                                        >
                                                            Choose Finished Image
                                                            <input
                                                                id="invit_image_finished"
                                                                className="image-input"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={this.uploadImage2}
                                                            />
                                                        </label>
                                                        <img
                                                            className="uploaded-image-preview"
                                                            src={this.state.finishedimage}
                                                            alt="Nothing Selected"
                                                        />
                                                        <span
                                                            id="full_image_valid"
                                                            className="validation-alert"
                                                        >
                                                            Please select a display image
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="primary"
                                                onClick={this.submitInvitation}
                                            >
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div>
                                        <div className='survey_page_add_question_form_container'>
                                            {show_add_question_form && (
                                                <div className="edit_app_notes">
                                                    <div className="add_tags_inner">
                                                        <h2>Create Tags</h2>
                                                        <br></br>
                                                        <form onSubmit={this.addQuestion}>

                                                            <div>
                                                                <input
                                                                    className="add_tag_input_field"
                                                                    placeholder='Enter tag'
                                                                    value={newOption}
                                                                    onChange={this.handleOptionChange}
                                                                />

                                                                <br />
                                                                <br />
                                                                <div>
                                                                    {/* {suggestedTags.map(tag => (
                                                                        <div key={tag.value}>
                                                                            <label> */}
                                                                    <div className='add_question_label'>
                                                                        <ul className="tag-grid">
                                                                            {suggestedTags.map(tag => (
                                                                                <li className="tag_lables" key={tag.value}>#{tag.label}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>



                                                                    {/* </label>
                                                                        </div>
                                                                    ))} */}
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className='editreleasenotespopupform'>
                                                                <button type="submit" disabled={isSubmitDisabled}>Submit</button>
                                                                <button className="close-btn" onClick={this.toogleAddQuestion} style={{ float: 'right' }}>
                                                                    Close form
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Invitations;