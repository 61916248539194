import React, { useEffect, useState } from 'react';
import axios from "axios";
import styled from 'styled-components'
import Navbar from "../../components/Navbar";
import Header from '../../components/Header/header';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
const Styles = styled.div`
  padding: 1rem;
  table {
  border-spacing: 0;
  border: 1px solid black;
  width:80%;
  margin:10px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th,td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    :last-child {
      border-right: 0;
    }
  }
}
`
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <div></div>
  )
}
function closeForm() {
  document.querySelector("#closeform").style.display = "none";
  document.querySelector('#showform').style.display = "flex";
}
function add(cell) {
  var tId = JSON.stringify(cell?.row?.original.ticketId)
  toast.info(tId)
  var relation_name = document.getElementById("res_name").value
  if (relation_name) {
    // alert("passed condition")
    // alert("relation name: "+relation_name);
    // alert("relation Id: "+relation_id);
    try {
      // axios.post(globaladdress+"address?relationName="+relation_name+"&relId="+relation_id+"&key="+globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(()=>{window.location.reload()})
      toast.info("function called in try bloack");
    } catch {
      toast.error("api call skipped!!")
    }
  } else {
    toast.warning("Please fill the required feilds!")
  }
}
const handleShow = (cell) => {
  var role = window.localStorage.getItem("role")
  if (role !== "Admin") {
    toast.warning("You are not authorized to perform this action!");
  } else {
    var stat = JSON.stringify(cell?.row?.original.ticketId)
    toast.info(stat);
    document.querySelector(".addresform").style.display = "flex";
    document.querySelector('#showform').style.display = "none";
  }
}
function Table({ columns, data }) {
  const filterTypes = (
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    showForm,
    // setPageSize,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )
  const firstPageRows1 = page.slice(0, 9)
  return (
    <>
      <h1>User issues</h1>
      <div>
        {/* <div className="addresponseform">
          <div className="addresform" id="closeform">
            <h1>Add Response</h1>
            <form className="add_res_form" id="addresponse_form">
              <div className="addresponse_form">
                <label>Response &nbsp;
                  <textarea id="res_name" name="postContent" />
                </label>
              </div>
              <br />
            </form>
            <div id="form_btn_section">
              <span><button id='addrel' type='submit' onClick={add}>Add Response</button>
                <button id='close_rel_form' type="submit" onClick={closeForm}>Close</button></span>
            </div>
          </div>
        </div> */}
        <table className='table data_table' {...getTableProps()}>
          <thead>
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: 'left',
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows1.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <br />
      <div id='filter_det'>
        Showing the{" "}
        {pageIndex * 10 + 1}-
        {(pageIndex + 1) * 10 > rows.length ? rows.length : (pageIndex + 1) * 10} results of {rows.length} rows      </div>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"First"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Last"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const rows = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(rows);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
      </div>
    </>
  )
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = val => typeof val !== 'number'
function App() {
  const updateData = () => {
    const editedTicketId = document.getElementById('edit_ticketId').value;
    const editedIssueT = document.getElementById('edit_issue_T').value;
    const editedIssueM = document.getElementById('edit_issue_M').value;
    const editedUserId = document.getElementById('edit_userId').value;
    const editedUserFirstName = document.getElementById('edit_u_firstName').value;
    const editedUserLastName = document.getElementById('edit_u_lastName').value;
    const editedUserEmail = document.getElementById('edit_u_email').value;
    const editedUserPhoneNumber = document.getElementById('edit_u_phoneNumber').value;
    const editedAssignee = document.getElementById('edit_assignee').value;
    const editedIssuedTime = document.getElementById('edit_issued_time').value;
    const editedRemarks = document.getElementById('edit_remarks').value;
    const editedStatus = document.getElementById('edit_status').value;
    // const editedRelId = document.getElementById('edit_rel_id').value;
    // alert("editedRelationName: " + editedRelationName); // Concatenate the values
    // alert("editedRelId: " + editedRelId);
    toast.info("api call to be updated");
    // axios.post(`${globaladdress}updaterelname?relName=${editedRelationName}&relId=${editedRelId}&key=${globalkey}`)
    //   .then((response) => {
    //     console.log('Data updated successfully');
    //     closeEditModal();
    //   })
    //   .catch((error) => {
    //     console.log("Update not went as smooth as expected!")
    //   });
  };
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const openEditModal = (row) => {
    setSelectedRow(row);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setSelectedRow(null);
    setEditModalOpen(false);
  };
  const populateEditForm = () => {
    if (!selectedRow) return null;
    const ticketId = selectedRow.original.ticketId;
    const issue_T = selectedRow.original.issue_t;
    const issue_M = selectedRow.original.issue_m;
    const userId = selectedRow.original.userId;
    const u_firstName = selectedRow.original.u_firstName;
    const u_lastName = selectedRow.original.u_lastName;
    const u_email = selectedRow.original.u_email;
    const u_phoneNumber = selectedRow.original.u_phoneNumber;
    const assignee = selectedRow.original.assignee;
    const issued_time = selectedRow.original.issued_time;
    const remarks = selectedRow.original.remarks;
    const status = selectedRow.original.status;
    return (
      <>
        <div className='edit_relation_lkp_form'>
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Ticket Id:
          </label>
          <input
            id="edit_ticketId"
            type="none"
            defaultValue={ticketId}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Issue T:
          </label>
          <input
            id="edit_issue_T"
            type="none"
            defaultValue={issue_T}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Issue M:
          </label>
          <input
            id="edit_issue_M"
            type="none"
            defaultValue={issue_M}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            User Id:
          </label>
          <input
            id="edit_userId"
            type="none"
            defaultValue={userId}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            User First Name:
          </label>
          <input
            id="edit_u_firstName"
            type="none"
            defaultValue={u_firstName}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            User Last Name:
          </label>
          <input
            id="edit_u_lastName"
            type="none"
            defaultValue={u_lastName}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            User email:
          </label>
          <input
            id="edit_u_email"
            type="none"
            defaultValue={u_email}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            User Mobile Number:
          </label>
          <input
            id="edit_u_phoneNumber"
            type="none"
            defaultValue={u_phoneNumber}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Assignee:
          </label>
          <input
            id="edit_assignee"
            type="none"
            defaultValue={assignee}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Issued Time:
          </label>
          <input
            id="edit_issued_time"
            type="none"
            defaultValue={issued_time}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Remarks:
          </label>
          <input
            id="edit_remarks"
            type="none"
            defaultValue={remarks}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Status:
          </label>
          <input
            id="edit_status"
            type="none"
            defaultValue={status}
            readOnly
            style={{ width: '300px' }}
          />
          <br />
          <hr />
          <label
            style={{ width: '200px', display: 'inline-block' }}>
            Status:
          </label>
          <input
            id="edit_status"
            type="text"
            defaultValue={status}
            style={{ width: '300px' }}
          />
          <br /><br />
        </div>
      </>
    );
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getusertickets?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          setData(res)

        }
      }).catch((error) => {
        console.log("Fetch error: ", error);
      })
  }, [])
  const columns = React.useMemo(() => [{
    Header: 'Name',
    columns: [
      {
        Header: 'Slno',
        accessor: 'ticketId',
      },
      {
        Header: 'issue_t',
        accessor: 'issue_t',
      },
      {
        Header: 'issue_m',
        accessor: 'issue_m',
      },
      {
        Header: 'userId',
        accessor: 'userId',
      },
      {
        Header: 'u_firstName',
        accessor: 'u_firstName',
      },
      {
        Header: 'User lastName',
        accessor: 'u_lastName',
      },
      {
        Header: 'User email',
        accessor: 'u_email',
      },
      {
        Header: 'User phoneNumber',
        accessor: 'u_phoneNumber',
      },
      {
        Header: 'Assignee',
        accessor: 'assignee',
      },
      {
        Header: 'Issued on',
        accessor: 'issued_time',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Edit',
        accessor: 'editrel',
        Footer: 'Edit',
        Cell: ({ row }) => (
          <button id='edit_relation_lkp_btn' onClick={() => openEditModal(row)}>Edit</button>
        ),
      },
    ],
  },
  ]
  )
  return (
    <div className="container body">
      <div className='main_container'>
        <div className='col-md-3 left_col'>
          <Navbar username={localStorage.username} />
        </div>
        <Header />
        <div className='right_col'>
          <div className='container2'>
            <div className='content'>
              <Styles>
                <Table
                  columns={columns}
                  data={data}
                />
              </Styles>
            </div>
            <Modal
              show={isEditModalOpen}
              onHide={closeEditModal}>
              <div className='edit_rel_lkp_model_container'>
                <Modal.Header>
                  <Modal.Title>Update User issue</Modal.Title>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeEditModal}>
                    <i className="fa fa-times"></i>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    {populateEditForm()}
                  </form>
                </Modal.Body>
                <div className='edit_rel_lkp_model_footer' >
                  <Modal.Footer>
                    <div className='edit_rel_lkp_model_btn'>
                      <Button
                        id='rel_lkp_update_btn'
                        variant="primary"
                        onClick={updateData}>
                        Update
                      </Button>
                    </div>
                  </Modal.Footer>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
export default App