import React, { Component } from "react";
import axios from "axios";
import { globalkey, globaladdress } from '../../page/variables'

class NewInvitationLayer extends Component {
  state = {
    selectedTemplate: this.props.template,
    selectedTempIndex: null,
    newtext_length: null,
    newtext_width: null,
    isbold: null,
    layers: [],
  }
  constructor(props) {

    super(props);
    this.changeboldStatus = this.changeboldStatus.bind(this);
    this.showNewLayer = this.showNewLayer.bind(this);
    this.inputcheck = this.inputcheck.bind(this);
    this.submitLayer = this.submitLayer.bind(this);

  }
  showNewLayer() {
    document.getElementById("new_layer").classList.add(['show_new']);
  }
  changeboldStatus() {
    var checkbox = document.getElementById("isBold");
    this.setState({ isbold: checkbox.checked })

  }
  inputcheck(e) {
    var el = e.currentTarget;
    var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
    if (el.value !== '' && !el.classList.contains('hastext')) {
      el.classList.add('hastext')
      if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    } else if (el.value === '' && el.classList.contains('hastext')) {
      el.classList.remove('hastext')
      alertmsg.add('invalid')
    }
  }
  submitLayer() {
    var l_1, l_2, l_3, l_4, l_5, l_6, l_7, l_8, l_9, l_10, formdata;
    l_1 = document.getElementById("layer_label").value;
    l_2 = document.getElementById("layer_value").value;
    l_3 = document.getElementById("layer_xpos").value;
    l_4 = document.getElementById("layer_ypos").value;
    l_5 = document.getElementById("layer_size").value;
    l_6 = document.getElementById("isBold").checked;
    l_7 = document.getElementById("layer_algn").value;
    l_8 = document.getElementById("layer_color").value;
    l_9 = document.getElementById("layer_length").value;
    l_10 = document.getElementById("layer_width").value;
    if (l_1 && l_2 && l_3 && l_4 && l_5 && l_7 && l_8 && l_9 && l_10) {
      formdata = new FormData();
      formdata.append("label", l_1);
      formdata.append("value", l_2);
      formdata.append("xpos", l_3);
      formdata.append("ypos", l_4);
      formdata.append("size", l_5);
      formdata.append("bold", l_6);
      formdata.append("alignment", l_7);
      formdata.append("color", l_8);
      formdata.append("text_length", l_9);
      formdata.append("layer_width", l_10);
      formdata.append("templateid", this.props.template.templateid);
      axios.post(globaladdress + 'addtemplatefields?key=' + globalkey, formdata)
        .then(res => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.text();
        })
        .then((resText) => {
          console.log("Full response:", resText);
          if (resText === "Session timeOut!") {
            alert("Session timeOut!");
            window.localStorage.clear();
            window.location.href = "/";
          } else {
            window.location.reload();
          }
        })
        .catch(error => {
          console.log("Axios error: ", error);
        });

    } else {
      alert("required fields cannot be empty!")
    }
  }
  closeLayer() {
    document.getElementById("new_layer").classList.remove(['show_new']);
  }
  render() {
    return <div id="new_layer" className="add_layer">
      <button type="button" className="close-layer" aria-label="Close" onClick={this.closeLayer}><i className="fa fa-times"></i></button>
      <h3 className="no-margin">New Layer</h3>
      <hr className="line_1" />
      <div className="fields">
        <div className="col-md-12">
          <div className="form-field">
            <input id="layer_label" className="special-input hastext" defaultValue={'FieldName'} onChange={this.inputcheck} />
            <label className="special-lable">label *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-field">
            <input id="layer_value" className="special-input hastext" defaultValue={'Field Value'} onChange={this.inputcheck} />
            <label className="special-lable">Default Value *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_length" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Text length *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_width" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Text width *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_xpos" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">X-pos *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_ypos" type="number" defaultValue={0} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Y-pos *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_size" className="special-input hastext" type="number" defaultValue={20} onChange={this.inputcheck} />
            <label className="special-lable">Size *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>


        <div className="col-md-6">
          <div className="form-field">
            <div className="layer_label_content">Bold :</div>
            <div className="toggle-switch">

              <input type="checkbox" className="checkbox"
                name='isBold' id='isBold' onChange={this.changeboldStatus} />

              <label className="label" htmlFor="isBold">
                <span className="inner" />
                <span className="switch" />
              </label>
            </div>

          </div>
        </div>
        <div className="col-md-10">
          <div className="form-field">
            <select id="layer_algn" className="special-input hastext" onChange={this.inputcheck}>
              <option value={1}>Left</option>
              <option value={2}>Center</option>
              <option value={3}>Right</option>

            </select>
            <label className="special-lable">Alignment *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-field">
            <input id="layer_color" type="color" defaultValue={'#000000'} className="special-input hastext" onChange={this.inputcheck} />
            <label className="special-lable">Color *</label>
            <span className="validation-alert"> * cannot be empty</span>
          </div>
        </div>

        <button className="btn-primary layer-btn floar-right-btn" onClick={this.submitLayer}><i className="fa fa-plus"></i> Add Layer</button>

      </div>
    </div>
  }
}
export default NewInvitationLayer;