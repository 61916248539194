import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Product_lkp extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.dragItem = React.createRef();
        this.dragOverItem = React.createRef();
        this.state = {
            selectedCategory: 'Family',
            showPopup: false,
            showFAQsForm: false,
            show_edit_FAQs_form: false,
            FAqs: [],
            inputValue: '',
        };
        this.categorychange = this.categorychange.bind(this);
        this.updateFAQsform = this.updateFAQsform.bind(this);
        this.addFAQs = this.addFAQs.bind(this);
        this.drop = this.drop.bind(this);
    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getFAQs?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        FAqs: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
    }
    togglePopup = () => {
        var role = window.localStorage.getItem('role');
        if (role !== 'Admin') {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showPopup: !this.state.showPopup });
        }
    };
    toggleedit_updateFAQs_form = (e) => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ c_question: e.currentTarget.dataset.qst, id: e.currentTarget.dataset.fqid, c_answer: e.currentTarget.dataset.ans, c_loi: e.currentTarget.dataset.loi, c_cat: e.currentTarget.dataset.catn })
            this.setState({ show_edit_FAQs_form: !this.state.show_edit_FAQs_form });
        }
    };
    updateFAQsform(event) {
        event.preventDefault();
        var dtset = {
            id: this.state.id,
            c_question: document.getElementById('edit_note_c_question').value,
            c_answer: document.getElementById('edit_note_c_answer').value
        }
        try {
            axios.post(globaladdress + "updateFAQs?id=" + dtset.id + "&question=" + dtset.c_question + "&answer=" + dtset.c_answer + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.text();
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload()

                    }
                })
        } catch {
            toast.error("api call skipped!!")
        }
    }
    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };
    categorychange(event) {
        var selcat = event.currentTarget.value;
        this.setState({ selectedCategory: selcat });
    }
    toggleFAQsForm = () => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showFAQsForm: !this.state.showFAQsForm });
        }
    }
    handleImageChange = (event) => {
        const file = event.target.files[0];
        this.setState({ imageFile: file });
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ imagePreview: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ imagePreview: null });
        }
    };
    addFAQs(event) {
        event.preventDefault();
        var question = document.getElementById('form_question').value;
        var answer = document.getElementById('form_answer').value;
        var category = this.state.selectedCategory;
        if (!question || !answer) {
            toast.warning("all fields are manditory!");
        } else {
            try {
                axios.post(globaladdress + "addFAQs?question=" + question + "&answer=" + answer + "&category=" + category + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            window.location.reload()

                        }
                    })
            } catch {
                toast.error("api call skipped!!")
            }
        }
    }
    toggleAnswer(e) {
        var otherel = document.getElementsByClassName('expand_faq');
        var el = e.currentTarget.parentElement;
        // Check if the clicked div is already expanded
        var isExpanded = el.classList.contains('expand_faq');
        // Close all other expanded divs
        for (var i = 0; i < otherel.length; i++) {
            otherel[i].classList.remove('expand_faq');
        }
        // If the clicked div was not already expanded, expand it
        if (!isExpanded) {
            el.classList.add('expand_faq');
        }
        // var otherel = document.getElementsByClassName('expand_faq');
        // console.log("otherel.length: ", otherel.length);
        // if (otherel.length != 0) {
        //     otherel[0].classList.remove('expand_faq');
        // }
        // var el = e.currentTarget;
        // if (el.parentElement.classList.contains('expand_faq')) {
        //     el.parentElement.classList.remove('expand_faq')
        // } else {
        //     el.parentElement.classList.add('expand_faq');
        // }
    }
    dragStart(e, position) {
        var otherel = document.getElementsByClassName('expand_faq');
        if (otherel.length != 0) {
            otherel[0].classList.remove('expand_faq');
        }
        this.dragItem.current = position;
    }
    dragEnter(e, position) {
        this.dragOverItem.current = position;
    }
    drop(e) {
        const copyListItems = [...this.state.FAqs];
        const dragItemContent = copyListItems[this.dragItem.current];
        copyListItems.splice(this.dragItem.current, 1);
        copyListItems.splice(this.dragOverItem.current, 0, dragItemContent);
        this.dragItem.current = null;
        this.dragOverItem.current = null;
        copyListItems.forEach((item, index) => {
            item.list_order = index + 1;
        })
        this.setState({ FAqs: copyListItems });
        const idArray = copyListItems.map(object => object.id);
        const listOrderArray = copyListItems.map(object => object.list_order);
        if (
            listOrderArray != '' ||
            listOrderArray != null ||
            listOrderArray != undefined ||
            idArray != '' ||
            idArray != null ||
            idArray != undefined
        ) {
            try {
                axios.post(globaladdress + "updateFAQs/order?id=" + idArray + "&list_order=" + listOrderArray + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            toast.success("list order updated!")

                        }
                        // Handle success response
                    })
                    .catch((error) => {
                        // Handle error
                        console.error('Error submitting order:', error);
                    });
            } catch {
                toast.error("api call skipped!!")
            }
        } else {
            toast.error('Something is strange Please try again later!');
        }
    };
    render() {
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar />
                    </div>
                    <Header />
                    <div className='right_col'>
                        <div className='container2'>
                            <div className='content'>
                                <div id='FAQs_l_btn'>
                                    <h3 id='FAQs_l_btn_h3'>FAQ's</h3>
                                    <button
                                        id='add_update_faqs'
                                        onClick={this.toggleFAQsForm}>
                                        To add FAQ's
                                    </button>
                                </div>
                                {this.state.showFAQsForm && (
                                    <div className="popup">
                                        <div className="popup-inner">
                                            <h2>Add FAQ's</h2>
                                            <form onSubmit={this.addFAQs}>
                                                <label>
                                                    Choose category:
                                                </label>
                                                <p style={{ color: '#999' }}>*Non-Editable Category Labe</p>
                                                <select
                                                    defaultValue={this.state.selectedCategory}
                                                    onChange={this.categorychange}>
                                                    <option value="Family">Family</option>
                                                    <option value="Friend">Friend's</option>
                                                    <option value="Events">Event's</option>
                                                    <option value="Circles">Circle's</option>
                                                    <option value="Requests">Request's</option>
                                                    <option value="Invitations">Invitation's</option>
                                                    <option value="Marketplace">Marketplace</option>
                                                    <option value="Settings">Settings</option>
                                                    <option value="Edit Profile">Edit Profile</option>
                                                </select>
                                                <br />
                                                <label>
                                                    Question:
                                                    <input
                                                        id='form_question'
                                                        type="text"
                                                    />
                                                </label>
                                                <br />
                                                <label>
                                                    Answer:
                                                    <textarea
                                                        id='form_answer'
                                                    />
                                                </label>
                                                <br />
                                                <div className='addFAQspopupform'>
                                                    <button type="submit">Send</button>
                                                    <button
                                                        className="close-btn"
                                                        onClick={this.toggleFAQsForm}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                <div className='alternate_list'>
                                    <ul className='faq_list'>
                                        {this.state.FAqs && this.state.FAqs.map((fq, index) => (
                                            <div className=' col-md-6 faqs_list'>
                                                <li className='faq_item'
                                                    key={index}
                                                    draggable onDragStart={(e) => this.dragStart(e, index)} onDragEnter={(e) => this.dragEnter(e, index)} onDragEnd={this.drop}>
                                                    <i className='fa fa-bars'></i>

                                                    <div className='faq_item_div'>
                                                        <a className='faq_question' onClick={this.toggleAnswer}>
                                                            {fq.question}<p style={{ float: "right" }}>Order: {fq.list_order}</p>
                                                        </a>
                                                        <p className='faq_cat'>Category: {fq.category}</p>
                                                        <p className='faq_answer'>{fq.answer}</p>
                                                        {/* <br /> */}
                                                        <button
                                                            id='edit_FAQs_btn'
                                                            data-fqid={fq.id}
                                                            data-qst={fq.question}
                                                            data-ans={fq.answer}
                                                            data-catn={fq.category}
                                                            data-loi={fq.list_order}
                                                            type="submit"
                                                            onClick={this.toggleedit_updateFAQs_form}>
                                                            Update
                                                        </button>
                                                    </div>
                                                </li>
                                            </div>
                                        ))}
                                    </ul>
                                    {this.state.show_edit_FAQs_form && (
                                        <div className="edit_FAQs">
                                            <div className="edit_FAQs-inner">
                                                <h2>Update FAQ's</h2>
                                                <form
                                                    id="editformFAQs"
                                                    style={{ display: 'table-caption' }}
                                                    onSubmit={this.updateFAQsform}>
                                                    <br />
                                                    <label>
                                                        Question:
                                                        <textarea
                                                            id='edit_note_c_question'
                                                            type="text"
                                                            style={{ width: "45rem" }}
                                                            defaultValue={this.state.c_question}
                                                        />
                                                    </label>
                                                    <br />
                                                    <label>
                                                        Answer:
                                                        <textarea
                                                            id='edit_note_c_answer'
                                                            type="text"
                                                            style={{ width: "45rem" }}
                                                            defaultValue={this.state.c_answer}
                                                        />
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <div className='editFAQspopupform'>
                                                        <button
                                                            id='update_FAQs_form_btn'
                                                            type="submit">
                                                            Send
                                                        </button>
                                                        <button
                                                            className="close-btn"
                                                            id='FAQs_edit_form_close_btn'
                                                            data-nid=''
                                                            data-title=''
                                                            data-msg=''
                                                            data-selopt='Bug Fix'
                                                            onClick={this.toggleedit_updateFAQs_form}>
                                                            Close form
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Product_lkp;