import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Product_lkp extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            option: '',
            category: '',
            showPopup: false,
            showFAQsForm: false,
            show_edit_products_lkp: false,
            title: '',
            type: '',
            message: '',
            products_lkp: [],
            id: '',
            inputValue: '',
            imageFile: null,
            imagePreview: null,
        };
        // this.state = {selectedDuration:''};
        // this.categorychange = this.categorychange.bind(this);
        // update form
        this.updateproducts_lkp = this.updateproducts_lkp.bind(this);
        this.deleteProductLkp = this.deleteProductLkp.bind(this);
        // this.handleImageChange
        // this.handleImageChange = this.handleImageChange(this);


        // this.optionChange=this.optionChange.bind(this);
    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getproductlkp?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        products_lkp: res,
                    });
                    console.log(res)
                }


            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
        // alert(this.state.products_lkp.length)
    }
    togglePopup = () => {
        var role = window.localStorage.getItem('role');
        if (role !== 'Admin') {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showPopup: !this.state.showPopup });

        }
    };
    toggleedit_products_lkp = (e) => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ c_name: e.currentTarget.dataset.catgname, id: e.currentTarget.dataset.catgid, stat: e.currentTarget.dataset.stat });
            this.setState({ show_edit_products_lkp: !this.state.show_edit_products_lkp });
        }
    };
    updateproducts_lkp(event) {
        event.preventDefault();
        var dtset = {
            id: this.state.id,
            c_name: document.getElementById('edit_note_c_name').value,
        }
        toast.info(JSON.stringify(dtset));
        try {
            axios.post(globaladdress + "updateproductlkp?id=" + dtset.id + "&c_name=" + dtset.c_name + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.text();
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload()

                    }
                })
        } catch {
            toast.error("api call skipped!!")
        }
    }
    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };

    handleImageChange = (event) => {
        const file = event.target.files[0];
        this.setState({ imageFile: file });
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ imagePreview: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ imagePreview: null });
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();
        var c_name = this.state.inputValue
        var image_File = this.state.imagePreview;
        var formdata = new FormData();
        formdata.append("c_name", c_name);
        formdata.append('image', this.state.imageFile);
        if (c_name === '' || c_name === undefined || c_name === null) {
            toast.warning('check input fields!')
        } else {
            toast.info("else");
            try {
                axios.post(globaladdress + "addproductlkp?c_name=" + c_name + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                    .then((res) => {
                        // Handle success response
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            const res = JSON.parse(resText);
                            window.location.reload()

                        }
                    })
                    .catch((error) => {
                        // Handle error
                        console.error('Error submitting form:', error);
                    });
                // .then(() => { window.location.reload() });
                // alert("function called in try bloack");
            } catch {
                toast.error("api call skipped!!")
            }

        }
        this.setState({
            inputValue: '',
            imagePreview: null,
        });
    };
    deleteProductLkp(event) {
        event.preventDefault();
        var detplkp = {
            id: this.state.id,
        }
        axios.post(globaladdress + "deleteproductlkp?id=" + detplkp.id + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                console.log("Full response:", resText);
                if (resText === "Session timeOut!") {
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    window.location.reload()

                }
            }).catch((error) => {
                console.log("ERROR IN DELETE FUNCTION : ", error)
            })
        toast.info(detplkp.id);
    }

    render() {
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    {/* <div className='page-content'> */}
                    <Header />
                    <div className='right_col'>
                        <div>
                            <button id="add_products_lkp_btn" onClick={this.togglePopup}>Add Product</button>
                            {this.state.showPopup && (
                                <div className="popup">
                                    <div className="popup-inner">
                                        <h2>Add Product</h2>
                                        <br></br>
                                        <form onSubmit={this.handleSubmit}>
                                            <input
                                                type="text"
                                                value={this.state.inputValue}
                                                onChange={this.handleInputChange}
                                                placeholder="Enter something..."
                                            />
                                            <br />
                                            <input
                                                type="file"
                                                onChange={this.handleImageChange}
                                                accept="image/*"
                                                id="imageUpload"
                                            />
                                            {this.state.imagePreview && (
                                                <div className="image-preview">
                                                    <img src={this.state.imagePreview} alt="Preview" />
                                                </div>
                                            )}
                                            <br></br>
                                            <div className='addproductslkppopupform'>
                                                <button type="submit">Add</button>
                                                <button className="close-btn" onClick={this.togglePopup}>
                                                    Close form
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='release_notes_tab'>
                            <div>

                            </div>
                            {this.state.products_lkp.length === 0 ? (
                                <div>
                                    <h4>No products found.</h4>
                                    <br />
                                </div>
                            ) : (
                                <table id='releasenotes_table' key={this.state.products_lkp.id}>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Category Name</th>
                                            <th>Status</th>
                                            <th>Category Image</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    {this.state.products_lkp.map((pl) => (
                                        <tbody>
                                            <tr>
                                                <td>{pl.id}</td>
                                                <td>{pl.category_name}</td>
                                                <td>{pl.status}</td>
                                                <td><img className="category_image" src={"data:image/png;base64," + pl.cat_image} /></td>
                                                <td><button id='edit_product_lkp_btn' data-catgid={pl.id} data-catgname={pl.category_name} data-stat={pl.status} type="submit" onClick={this.toggleedit_products_lkp}>Update</button></td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            )}

                            {this.state.show_edit_products_lkp && (
                                <div className="edit_products_lkp">
                                    <div className="edit_products_lkp-inner">
                                        <h2>Update Product Lookup's</h2>
                                        <br></br>
                                        <button id="delete_product_lkp_btn" style={{ float: 'right' }} onClick={this.deleteProductLkp}>Delete</button>
                                        <br></br><br></br>
                                        <form onSubmit={this.updateproducts_lkp}>
                                            <label>
                                                Category Name:&emsp;
                                                <input id='edit_note_c_name' type="text" defaultValue={this.state.c_name} />
                                            </label>
                                            <br />
                                            <br />
                                            <br></br>
                                            <div className='editreleasenotespopupform'>
                                                <button id='update_product_lkp_btn' type="submit">Send</button>

                                                <button className="close-btn" id="toggleedit_products_lkp_close_btn" data-nid='' data-title='' data-msg='' data-selopt='Bug Fix' style={{ float: 'right', padding: '10px 10px;' }} onClick={this.toggleedit_products_lkp}>
                                                    Close form
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Product_lkp;