import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CSS/fonts.css';
import { RiDeleteBin6Line } from "react-icons/ri";

class PopupForm extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            shoeedit_fonts: false,
            title: '',
            type: '',
            message: '',
            faqs: [],
            id: '',
            fontName: '',
            fontId: '',
        };
        this.hfdleSubmit = this.hfdleSubmit.bind(this);
        this.updatefonts = this.updatefonts.bind(this);
        this.handleDeleteFont = this.handleDeleteFont.bind(this);
    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getfonts?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        faqs: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
    }
    togglePopup = () => {
        var role = window.localStorage.getItem('role');

        if (role !== 'Admin') {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showPopup: !this.state.showPopup });
        }
    };
    toogle_edit_fonts = (e) => {
        var role = window.localStorage.getItem('role');
        const fontId = e.currentTarget.dataset.nid;
        const fontName = e.currentTarget.dataset.fontname;
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ fontId: fontId, fontName: fontName });
            // alert(this.state.selectedOption);
            this.setState({ shoeedit_fonts: !this.state.shoeedit_fonts });
        }
    };
    hfdleSubmit(event) {
        event.preventDefault();
        var title = document.getElementById('fontName').value;
        if (title) {
            try {
                axios.post(globaladdress + "adminaddfonts?f_name=" + title + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                    .then((res) => {
                        return res.data;
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else if (resText === "New Font added!") {
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        // Handle error here if necessary
                    });

            } catch {
                toast.error("api call skipped!!")
            }
        } else {
            toast.info("Please enter the font Name");
        }
    }
    updatefonts(event) {
        event.preventDefault();
        var dtset = {
            id: this.state.fontId,
            title: document.getElementById('edit_fontName').value,
        }
        alert(JSON.stringify(dtset));
        try {
            axios.post(globaladdress + "updatefonts?fontName=" + dtset.title + "&fontId=" + dtset.id + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    return res.data;
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else if (resText === "Font Updated!") {
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    // Handle error here if necessary
                });

        } catch {
            toast.error("api call skipped!!")
        }
    }
    handleDeleteFont(event) {
        event.preventDefault();
        const d_font_id = this.state.fontId;
        alert("you are about to delete the selected font");
        try {
            axios.delete(globaladdress + "deletefont?&fontId=" + d_font_id + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    return res.data;
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else if (resText === "Font deleted!") {
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    // Handle error here if necessary
                });

        } catch {
            toast.error("api call skipped!!")
        }
    }
    render() {
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    <Header />
                    <div className='right_col'>
                        <div className='table_container'>
                            <div>
                                <button
                                    id="add_release_notes_btn"
                                    onClick={this.togglePopup}>
                                    Add New Font
                                </button>
                                {this.state.showPopup && (
                                    <div className="popup">
                                        <div className="popup-inner">
                                            <h2>Add New Font</h2>
                                            <br></br>
                                            <form onSubmit={this.hfdleSubmit}>
                                                <label>
                                                    Font Name:
                                                    <input id='fontName' type="text" />
                                                </label>
                                                <br />
                                                <br />
                                                <div className='addreleasenotespopupform'>
                                                    <button type="submit">Add Font</button>
                                                    <button
                                                        className="close-btn"
                                                        style={{ float: 'right' }}
                                                        onClick={this.togglePopup}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='release_notes_tab'>
                                <table
                                    id='releasenotes_table'
                                    key={this.state.faqs.id}>
                                    <tr>
                                        <th>Id</th>
                                        <th>Font Name</th>
                                        <th>Edit</th>
                                    </tr>
                                    {this.state.faqs && this.state.faqs.map((f) => (
                                        <tr>
                                            <td>{f.id}</td>
                                            <td>{f.f_name}</td>
                                            <td>
                                                <button
                                                    id='edit_release_notes'
                                                    data-nid={f.id}
                                                    data-fontname={f.f_name}
                                                    type="submit"
                                                    onClick={this.toogle_edit_fonts}>
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                                {this.state.shoeedit_fonts && (
                                    <div className="update-font-popup-container">
                                        <div className="update-font-popup-background"></div>
                                        <div className="edit_fonts">
                                            <div className="edit_fonts-inner">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <h2>Update Fonts</h2>
                                                    <button className="questions_container_buttons" title="Delete Question" onClick={this.handleDeleteFont}>
                                                        <RiDeleteBin6Line style={{ color: "#026FBF", width: "24px", height: "24px" }} />
                                                    </button>
                                                </div>
                                                <br />
                                                <form onSubmit={this.updatefonts}>
                                                    <label>
                                                        Font Name:
                                                        <input
                                                            id='edit_fontName'
                                                            type="text"
                                                            defaultValue={this.state.fontName}
                                                        />
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <div className='editfontspopupform'>
                                                        <button
                                                            className='edit_font_submit_btn'
                                                            type="submit">
                                                            Update Font
                                                        </button>
                                                        <button
                                                            className="close-btn"
                                                            data-nid=''
                                                            data-title=''
                                                            data-msg=''
                                                            data-selopt='Bug Fix'
                                                            style={{ float: 'right', padding: '10px' }}
                                                            onClick={this.toogle_edit_fonts}>
                                                            Close form
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PopupForm;