import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import axios from "axios";
import { globalkey, globaladdress, s_key, s_secret } from '../../variables'
import NewInvitationLayer from "../../../components/InvitationLayer/new_invitationlayer";
import NewInvitationLayerNofields from "../../../components/InvitationLayer/new_invitationlayer_without_fields";
import { toast } from 'react-toastify';
import { FaRegEye } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import './invitations.css';
class LayerDetails extends Component {
    state = {
        showModal: false,
        selectedTempIndex: null,
        selectedTemplate: {
            user_role_acc: null,
            templateid: null,
            templatename: null,
            status: null,
            full: null,
            category: null,
            font: null,
            price: null,
            text_length: null,
            text_width: null,
            userid: null,
            description: null,
        },
        back: null,
        isenablestempl: null,
        editprice: false,
        user_role_acc: window.localStorage.getItem('role'),
        newtext_length: null,
        newtext_width: null,
        newprice: null,
        isbold: null,
        showPreview: false,
        layers: [],
        templateTags: [],
        templateTagsSelected: [],
        flabel: null,
        fvalue: null,
        selectedTags: [],

    }
    constructor(props) {
        super(props);
        this.updateLayer = this.updateLayer.bind(this);
        this.changetempStatus = this.changetempStatus.bind(this);
        this.editPrice = this.editPrice.bind(this);
        this.submitPrice = this.submitPrice.bind(this);
        this.updateTemp = this.updateTemp.bind(this);
        this.changeboldStatus = this.changeboldStatus.bind(this);
        this.showNewLayer = this.showNewLayer.bind(this);
        this.showNewLayerwithoutfields = this.showNewLayerwithoutfields.bind(this);
        this.inputcheck = this.inputcheck.bind(this);
        this.submitLayer = this.submitLayer.bind(this);
        this.preview = this.preview.bind(this);
        this.hidePreview = this.hidePreview.bind(this);
        this.updateTags = this.updateTags.bind(this);

    }
    componentDidMount() {
        this.setState({ selectedTemplate: this.props.template });
        this.setState({ selectedTags: this.state.templateTagsSelected });
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "gettemplateback?key=" + globalkey + "&tId=" + this.props.template.templateid + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        back: res[0].background_image,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "gettemplatefields?key=" + globalkey + "&tId=" + this.props.template.templateid + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        layers: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "gettemplatetags?key=" + globalkey + "&tId=" + this.props.template.templateid + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTags: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "gettemplatetagslkp?key=" + globalkey + "&tId=" + this.props.template.templateid + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTagsSelected: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });

    }

    changetempStatus() {
        var checkbox = document.getElementById("isEnabled");
        this.setState({ isbold: checkbox.checked })
        var sts;
        checkbox.checked === true ? sts = 1 : sts = 0
        this.state.selectedTemplate.status = sts;
    }
    changeboldStatus() {
        var checkbox = document.getElementById("isBold");
        this.setState({ isbold: checkbox.checked })
    }
    editPrice() {
        this.setState({ editprice: !this.state.editprice });
    }
    submitPrice() {
        var newprice = document.getElementById("edit_price").value;
        this.setState({ editprice: !this.state.editprice, newprice: newprice });
        this.state.selectedTemplate.price = newprice;
    }
    preview() {
        this.setState({ showPreview: true })
    }
    hidePreview() {
        this.setState({ showPreview: false })
    }
    updateTemp() {
        var sts;
        this.state.isenablestempl === true ? sts = 1 : sts = 0
        var formdata = new FormData();
        formdata.append("tId", this.state.selectedTemplate.templateid);
        formdata.append("status", this.state.selectedTemplate.status);
        formdata.append("price", this.state.selectedTemplate.price);
        axios.post(globaladdress + 'updatetemplate?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!") {
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    const updatedTemplate = res.data;
                    this.props.updateSelectedTemplate(updatedTemplate);
                    this.props.updateSelectedTemplate(res.data.updatedTemplate);
                }

            })
            .catch(error => {
                console.log(error);
            });
    }
    updateTemplatePrice = (newPrice) => {
        this.setState((prevState) => ({
            selectedTemplate: {
                ...prevState.selectedTemplate,
                price: newPrice,
            },
        }));
    };
    showNewLayer() {
        var showinglist = document.getElementById("new_layer2");
        if (showinglist && showinglist.classList.contains(['show_new']))
            showinglist.remove(['show_new']);
        document.getElementById("new_layer").classList.add(['show_new']);
    }
    showNewLayerwithoutfields(e) {
        this.setState({
            flabel: e.currentTarget.dataset.fl,
            fvalue: e.currentTarget.dataset.fv,
        });
        var showinglist = document.getElementById("new_layer");
        if (showinglist && showinglist.classList.contains(['show_new']))
            showinglist.remove(['show_new']);
        document.getElementById("new_layer2").classList.add(['show_new']);
    }
    closeLayer() {
        document.getElementById("new_layer").classList.remove(['show_new']);
        document.getElementById("new_layer2").classList.remove(['show_new']);
    }
    inputcheck(e) {
        var el = e.currentTarget;
        var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
        if (el.value !== '' && !el.classList.contains('hastext')) {
            el.classList.add('hastext')
            if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
        } else if (el.value === '' && el.classList.contains('hastext')) {
            el.classList.remove('hastext')
            alertmsg.add('invalid')
        }
    }
    submitLayer() {
        var l_1, l_2, l_3, l_4, l_5, l_6, l_7, l_8, l_9, l_10, formdata;
        l_1 = document.getElementById("layer_label").value;
        l_2 = document.getElementById("layer_value").value;
        l_3 = document.getElementById("layer_xpos").value;
        l_4 = document.getElementById("layer_ypos").value;
        l_5 = document.getElementById("layer_size").value;
        l_6 = document.getElementById("isBold").checked;
        l_7 = document.getElementById("layer_algn").value;
        l_8 = document.getElementById("layer_color").value;
        l_9 = document.getElementById("layer_length").value;
        l_10 = document.getElementById("layer_width").value;
        if (l_1 && l_2 && l_3 && l_4 && l_5 && l_7 && l_8 && l_9 && l_10) {
            formdata = new FormData();
            formdata.append("label", l_1);
            formdata.append("value", l_2);
            formdata.append("xpos", l_3);
            formdata.append("ypos", l_4);
            formdata.append("size", l_5);
            formdata.append("bold", l_6);
            formdata.append("alignment", l_7);
            formdata.append("color", l_8);
            formdata.append("text_length", l_9);
            formdata.append("layer_width", l_10);
            formdata.append("templateid", this.state.selectedTemplate.templateid);
            axios.post(globaladdress + 'addtemplatefields?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.text();
                })
                .then((resText) => {
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            toast.warning("required fields cannot be empty!")
        }
    }
    editLayer(e) {
        var parent = e.currentTarget.parentNode.parentNode;
        var contains = parent.classList.contains('editing')
        if (contains == false) {
            parent.classList.add('editing');
        }
    }
    stopediting() {
        var el = document.getElementsByClassName("editing")[0];
        el.classList.remove('editing');
    }
    updateLayer(e) {
        var tfid = e.currentTarget.dataset.tfid;
        var xpos, ypos, size, bold, alignment, color, text_length, text_width;
        xpos = document.getElementById("layer_xpos_" + tfid).value;
        ypos = document.getElementById("layer_ypos_" + tfid).value;
        size = document.getElementById("layer_size_" + tfid).value;
        bold = document.getElementById("isbold" + tfid).checked;
        alignment = document.getElementById("layer_algn_" + tfid).value;
        color = document.getElementById("layer_color_" + tfid).value;
        text_length = document.getElementById("layer_length_" + tfid).value;
        text_width = document.getElementById("layer_width_" + tfid).value;
        if (xpos && ypos && size && alignment && color && text_length) {
            var formdata = new FormData();
            formdata.append("tfid", tfid);
            formdata.append("xpos", xpos);
            formdata.append("ypos", ypos);
            formdata.append("size", size);
            formdata.append("bold", bold);
            formdata.append("alignment", alignment);
            formdata.append("color", color);
            formdata.append("text_length", text_length);
            formdata.append("layer_width", text_width);
            axios.post(globaladdress + 'updatetemplatefields?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tId=' + localStorage.tId, formdata)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("success!");
                            window.location.reload();
                        }
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } else {
            toast.warning("required fields cannot be empty")
        }
    }
    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    }
    updateTags = (e) => {
        var selectedTags = this.state.selectedTags;
        var selectedTagsFinal = JSON.stringify(selectedTags);
        const templateId = this.state.selectedTemplate.templateid
        alert(templateId + ' ' + selectedTagsFinal)
        if (selectedTagsFinal != '[]') {
            // api handling
            var selTags = selectedTags.map(tag => tag.value).join(',');
            axios.post(globaladdress + 'updatetemplatetags?key=' + globalkey + '&tag=' + selTags + '&templateId=' + templateId + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tId=' + localStorage.tId)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("Tags added successfully.");
                            window.location.reload();
                        }
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } else {
            alert("Please select at least one tag");
        }

    }
    render() {
        const tagOptions = this.state.templateTags.map(item => ({ value: item.value, label: item.label }));
        const existingTagOptions = this.state.templateTagsSelected.map(tag => ({ value: tag.value, label: tag.label }));

        return <div className="invitation_view" style={{ width: "max-content" }}>
            <div className="non-editable-details">
                <div className="templ_view_container">
                    <img
                        className="templ_image_view"
                        src={"data:image/png;base64," + this.state.selectedTemplate.full}
                        alt="Invitaion preview"
                    />
                    <div className="toggle_container">
                        {this.state.user_role_acc === "Admin" && <div className="">
                            <div className="label_content">Enable :</div>
                            {this.state.selectedTemplate.status === 1 && <div className="toggle-switch">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    name='isEnabled'
                                    id='isEnabled'
                                    checked={true}
                                    onChange={this.changetempStatus}
                                />
                                <label className="label" htmlFor="isEnabled">
                                    <span className="inner" />
                                    <span className="switch" />
                                </label>
                            </div>}
                            {this.state.selectedTemplate.status === 0 && <div className="toggle-switch">
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    name='isEnabled'
                                    id='isEnabled'
                                    onChange={this.changetempStatus}
                                />
                                <label className="label" htmlFor="isEnabled">
                                    <span className="inner" />
                                    <span className="switch" />
                                </label>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div className="templ_view_details">
                    <h2
                        className="templ_view_title">
                        {this.state.selectedTemplate.templatename}
                        <a className="preview_btn" onClick={this.preview} style={{ color: "inherit" }}>
                            <FaRegEye />
                        </a>

                    </h2>
                    <h5 className="templ_view_category">Category: {this.state.selectedTemplate.category}</h5>
                    <h5 className="templ_view_font"> Font: {this.state.selectedTemplate.font}</h5>
                    <p className="templ_description">{this.state.selectedTemplate.description}</p>
                    {this.state.editprice === false && <div className="" >
                        <h2 id="new_price" className="templ_price"><i className="fa fa-rupee"></i>{this.state.selectedTemplate.price}</h2>
                        {this.state.user_role_acc === "Admin" && <a className="edit_price" id="edit_price_t" onClick={this.editPrice} > <i className="fa fa-pencil"></i> </a>}
                    </div>
                    }
                    {this.state.editprice === true && <div className=""><i className="fa fa-rupee"></i><input id="edit_price" defaultValue={this.state.selectedTemplate.price} /><a className="submit_price" onClick={this.submitPrice}><i className="fa fa-check"></i></a></div>}
                    {this.state.user_role_acc === "Admin" && <button className="btn btn-primary" onClick={this.updateTemp} > update </button>}
                    <div className="template_tags_container">
                        <div className="template_tags_container">
                            {this.state.templateTagsSelected.map(tag => (
                                <div key={tag.value}>
                                    {tag.label.split(',').map((label, index) => (
                                        <p className="template_tags" key={index}>{`#${label.trim()}`}</p>
                                    ))}
                                </div>
                            ))}
                        </div>

                    </div>
                    <div>
                    </div>
                </div>
                <div style={{ width: "20%" }}>
                    <div className="choose_tags">

                        <Select
                            defaultValue={[]}
                            isMulti
                            name="tags"
                            options={tagOptions}
                            className="basic-multi-select"
                            id="selected-tags"
                            classNamePrefix="select"
                            placeholder="Select tags ..."
                            onChange={this.handleTagChange}
                        />
                        <div>
                            <button className="update_tags_btn" onClick={this.updateTags}>Update Tags</button>

                        </div>

                    </div>
                </div>

            </div>
            <div className="editables">
                {this.state.user_role_acc === "Admin" &&
                    <div style={{ padding: "10px" }}>
                        <h3 className="layer_head">Layers</h3>
                        <button
                            className="layer-btn"
                            data-fl="Time"
                            data-fv='10:10pm'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Time
                        </button>
                        <button
                            className="layer-btn"
                            data-fl="Day"
                            data-fv='Wednesday'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Day
                        </button>
                        <button
                            className="layer-btn"
                            data-fl="Date"
                            data-fv='23'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Date
                        </button>
                        <button
                            className="layer-btn"
                            data-fl="Month"
                            data-fv='March'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Month
                        </button>
                        <button
                            className="layer-btn"
                            data-fl="Year"
                            data-fv='2023'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Year
                        </button>
                        <button
                            className="layer-btn"
                            data-fl="Venue"
                            data-fv='Adress'
                            onClick={this.showNewLayerwithoutfields}
                        >
                            <i className="fa fa-plus"></i>
                            Venue
                        </button>
                        <button
                            className="layer-btn"
                            onClick={this.showNewLayer}
                        >
                            <i className="fa fa-plus"></i>
                            Add Layer
                        </button>
                    </div>
                }
                <hr />
                <div id="template_layers" className="layers">
                    <NewInvitationLayer
                        key={this.state.flabel}
                        template={this.state.selectedTemplate}
                    />
                    <NewInvitationLayerNofields
                        key={this.state.flabel}
                        flabel={this.state.flabel}
                        labelval={this.state.fvalue}
                        tid={this.state.selectedTemplate.templateid}
                    />
                    <div className="Layer List" id="layers_list" style={{ padding: "10px" }}>
                        <ul className="no-list">
                            {this.state.layers.map((layer) => {
                                return <li className="layer_list_item" key={layer.tfId}>
                                    <a className="edit_layer">
                                        <div className="layer_card">
                                            <h4>{layer.lable} : {layer.value} {layer.f_weight == "true" && <span className="boldtext">Bold</span>} </h4>
                                            <span className="stop_editing" onClick={this.stopediting}>
                                                <i className="fa fa-times"></i>
                                            </span>
                                            {this.state.user_role_acc === "Admin" &&
                                                <span className="start_editing" onClick={this.editLayer}>
                                                    <i className="fa fa-pencil"></i>
                                                </span>
                                            }
                                            <h6
                                                className="layer_params"
                                            >
                                                Font Size: {layer.f_size} |
                                                X: {layer.x_pos} |
                                                Y: {layer.y_pos} |
                                                Text_length: {layer.text_length} |
                                                Layer_width: {layer.layer_width} |
                                                Alignment: {layer.alignment == 1 && 'left'} {layer.alignment == 2 && 'Center'} {layer.alignment == 3 && 'Right'}
                                            </h6>
                                            <div className="layer_color_ribbon" style={{ backgroundColor: layer.t_color }}>
                                                <h6>Text Color</h6>
                                            </div>
                                        </div>
                                        <div className="edit_fields">
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_xpos_" + layer.tfId}
                                                        type="number"
                                                        defaultValue={layer.x_pos}
                                                        className="special-input hastext"
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">X-pos *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_ypos_" + layer.tfId}
                                                        type="number"
                                                        defaultValue={layer.y_pos}
                                                        className="special-input hastext"
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">Y-pos *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_size_" + layer.tfId}
                                                        className="special-input hastext"
                                                        type="number"
                                                        defaultValue={layer.f_size}
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">Size *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_length_" + layer.tfId}
                                                        className="special-input hastext"
                                                        type="number"
                                                        defaultValue={layer.text_length}
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">Text length *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_width_" + layer.tfId}
                                                        className="special-input hastext"
                                                        type="number"
                                                        defaultValue={layer.layer_width}
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">Text width *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <div className="layer_label_content">Bold :</div>
                                                    {layer.f_weight == 'true' && <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox"
                                                            name='isBold'
                                                            id={'isbold' + layer.tfId}
                                                            checked='checked'
                                                            onChange={this.changeboldStatus}
                                                        />
                                                        <label className="label" htmlFor={'isbold' + layer.tfId}>
                                                            <span className="inner" />
                                                            <span className="switch" />
                                                        </label>
                                                    </div>}
                                                    {layer.f_weight != 'true' && <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox"
                                                            name='isBold'
                                                            id={'isbold' + layer.tfId}
                                                            onChange={this.changeboldStatus}
                                                        />
                                                        <label className="label" htmlFor={'isbold' + layer.tfId}>
                                                            <span className="inner" />
                                                            <span className="switch" />
                                                        </label>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <select
                                                        id={"layer_algn_" + layer.tfId}
                                                        className="special-input hastext"
                                                        defaultValue={layer.alignment}
                                                        onChange={this.inputcheck}
                                                    >
                                                        <option value={1}>Left</option>
                                                        <option value={2}>Center</option>
                                                        <option value={3}>Right</option>
                                                    </select>
                                                    <label className="special-lable">Alignment *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <div className="form-field">
                                                    <input
                                                        id={"layer_color_" + layer.tfId}
                                                        type="color"
                                                        defaultValue={layer.t_color}
                                                        className="special-input hastext"
                                                        onChange={this.inputcheck}
                                                    />
                                                    <label className="special-lable">Color *</label>
                                                    <span className="validation-alert"> * cannot be empty</span>
                                                </div>
                                            </div>
                                            <div className="w-40">
                                                <div className="form-field">
                                                    <button
                                                        className="btn-primary layer-btn"
                                                        data-tfid={layer.tfId}
                                                        onClick={this.updateLayer}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                show={this.state.showPreview}
                onHide={this.hidePreview}
                backdrop="static"
                dialogClassName="modal-mobile"
                keyboard={false}
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={this.hidePreview}
                    >
                        <i className="fa fa-times"></i>
                    </button>
                    <img
                        className="preview_back"
                        src={"data:image/png;base64," + this.state.back}
                    />
                    {this.state.layers.map((layer) => {
                        console.log(this.state.layers)
                        var fontweight, textalign, xpos, ypos, size, l_width;
                        size = parseInt(layer.f_size) / 3;
                        xpos = parseInt(layer.x_pos) / 3 - 10;
                        ypos = parseInt(layer.y_pos) / 3 - 15;
                        l_width = parseInt(layer.layer_width) / 3;
                        if (layer.alignment === 1) {
                            textalign = "left"
                        } else if (layer.alignment === 2) {
                            textalign = "center"
                        } else {
                            textalign = "right"
                        }
                        layer.f_weight ? (fontweight = 700) : (fontweight = 400);
                        // Set font family to the value from layer.font, or inherit if undefined or incorrect
                        const fontFamily = layer.font ? layer.font : 'sans-serif';
                        return (
                            <div
                                className="preview_layer"
                                style={{ top: ypos + "px", left: xpos + "px", width: l_width + "px", textAlign: textalign, fontFamily: fontFamily }}
                            >
                                <h4
                                    style={{
                                        color: layer.t_color,
                                        fontSize: size + "px",
                                        fontWeight: fontweight,
                                        fontFamily: fontFamily
                                    }}
                                >
                                    {layer.value}
                                </h4>
                            </div>
                        );
                    })}
                </Modal.Body>
            </Modal>


        </div>
    }
}
export default LayerDetails;