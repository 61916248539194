import React, { Component } from "react";
// import Navbar from "./components/Navbar";
import Members from './page/Team/members';
import Users from './page/User_management/users/users';
import Invitations from './page/Resources/Invitations/invitations';
import Landingpage from './page/Login/landingpage';
import DashBoard from './page/Dashboard/dashboard';
import Events from './page/events';
import Datatable from './page/datatable';
import UserReports from './page/User_management/users/userReports';
import Addimage from './page/addImg';
import AddRelations from './page/Resources/addrelations';
import Transactions from './page/Resources/transactions';
import Helpsupport from './page/User_management/help';
import AddUpdates from './page/Resources/add_updates';
import Products from './page/Resources/products';
import DeletedUsers from './page/User_management/deleted_users';
import ProductsLkp from './page/Resources/products_lkp';
import FAQs from './page/Resources/FAQs';
import InvitationsExt from "./page/reqst_pages/invitations_ext_page";
import Fonts from './page/Resources/fonts';
import Profile from './page/Team/profile';
import Surveys from './page/Resources/surveys';
import Tags from './page/Resources/tags';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = { apiResponse: "", userloggedin: false };
    }
    callAPI() {
        // fetch("http://192.168.1.23:3003")
        //     .then(res => res.text())
        //     .then(res => this.setState({ apiResponse: res }))
        //     .catch(err => err);

    }
    componentDidMount() {
        this.callAPI();
    }
    render() {
        return (
            <Router>

                <Routes>
                    <Route path='/' element={<Landingpage />} />
                    <Route path='/inviations' element={<Invitations />} />
                    <Route path='/members' element={<Members />} />
                    <Route path='/users' element={<Users />} />
                    <Route path='/dashboard' element={<DashBoard />} />
                    <Route path='/events' element={<Events />} />
                    <Route path='/datatable' element={<Datatable />} />
                    <Route path='/userreports' element={<UserReports />} />
                    <Route path='/addimage' element={<Addimage />} />
                    <Route path='/addrelations' element={<AddRelations />} />
                    <Route path='/transactions' element={<Transactions />} />
                    <Route path='/help' element={<Helpsupport />} />
                    <Route path='/addupdates' element={<AddUpdates />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/deletedUsers' element={<DeletedUsers />} />
                    <Route path='/products.lkp' element={<ProductsLkp />} />
                    <Route path='/invitations.ext' element={<InvitationsExt />} />
                    <Route path="/faqs" element={<FAQs />} />
                    <Route path="/fonts" element={<Fonts />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/jbSurveys" element={<Surveys />} />
                    <Route path="/tags" element={<Tags />} />
                </Routes>
            </Router>
        );
    }
}
export default App;
export const globalkey = "thisisReacturl";