import React from "react";
import ReactLoading from "react-loading";
import Navbar from "../../../components/Navbar";
import Header from '../../../components/Header/header';
import Modal from 'react-bootstrap/Modal';
import LogoWhite from '../../assets/images/logo5.svg'
import { globalkey, globaladdress, s_key, s_secret } from '../../variables'
import LayerDetails from "./LayerComponent";
import "./users.css";
/*

*/
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      searchText: '',
      DataisLoaded: false,
      usersList: [],
      maintenancestat: null,
      selectedUsrIndex: null,
      role: window.localStorage.getItem('role'),
      selectedUser: {
        status: null,
        firstName: null,
        email: null
      },
      showModal: false,
      showModal1: false,
      currentPage: 1,
      itemsPerPage: 20,
    };
    this.inputcheck = this.inputcheck.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow1 = this.handleShow1.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.getTotalPages = this.getTotalPages.bind(this);
  }
  handleInputChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  handleSearch = (event) => {
    this.setState({ searchText: event.target.value, currentPage: 1 });
  };

  getTotalPages() {
    const { usersList, itemsPerPage, searchText } = this.state;
    const filteredList = usersList.filter(
      (item) =>
        item.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.mobile.toLowerCase().includes(searchText.toLowerCase())
    );
    return Math.ceil(filteredList.length / itemsPerPage);
  }
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };
  handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };
  handleNextPage = () => {
    const { currentPage } = this.state;
    const totalPages = this.getTotalPages();
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };
  signincheck() {
    if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  }
  handleClose() { this.setState({ showModal: false }) }
  handleClose1() { this.setState({ showModal1: false }) }
  handleShow() { this.setState({ showModal: true }) }
  handleShow1() { this.setState({ showModal1: true }) }
  componentDidMount() {
    let sessionTimeOutAlertShown = false;
    this.signincheck();
    fetch(globaladdress + "getcustomerslist?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            usersList: res,
          });
        }

      }).catch((error) => {
        console.log(`Error in fetching data from server ${error}`);
      })
    fetch(globaladdress + "getmaintenancestatus?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            maintenancestat: res,
          });
        }

      }).catch((error) => {
        console.error("Fetch error: ", error)
      })
  }
  inputcheck(e) {
    var el = e.currentTarget;
    var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
    if (el.value !== '' && !el.classList.contains('hastext')) {
      el.classList.add('hastext')
      if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    } else if (el.value === '' && el.classList.contains('hastext')) {
      el.classList.remove('hastext')
      alertmsg.add('invalid')
    }
  }
  selectUser(e) {
    var unselect = document.getElementsByClassName('selected');
    if (unselect.length !== 0) unselect[0].classList.remove('selected');
    e.currentTarget.classList.add('selected');
    var cdId = e.currentTarget.dataset.cdid;
    var index = this.state.usersList.findIndex(x => x.cdId == cdId);
    const seltusr = {
      cdid: this.state.usersList[index].cdId,
      firstname: this.state.usersList[index].firstName,
      lastname: this.state.usersList[index].lastName,
      displayname: this.state.usersList[index].displayName,
      mobile: this.state.usersList[index].mobile,
      email: this.state.usersList[index].email,
      dob: this.state.usersList[index].dob,
      gender: this.state.usersList[index].gender,
      maritalstatus: this.state.usersList[index].maritalstatus,
      address: this.state.usersList[index].address,
      district: this.state.usersList[index].district,
      state: this.state.usersList[index].state,
      country: this.state.usersList[index].country,
      cid: this.state.usersList[index].cId,
      image: this.state.usersList[index].image,
    }
    this.setState({ selectedUser: seltusr, selectedUsrIndex: cdId, showModal: true });
  }
  render() {
    const {
      usersList,
      searchText,
      DataisLoaded,
      currentPage,
      itemsPerPage,
    } = this.state;
    console.log(usersList);
    // Calculate the filtered list based on the search text
    const filteredList = usersList
      .filter(
        (item) =>
          item.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.mobile.toLowerCase().includes(searchText.toLowerCase()) ||
          (item.firstName + ' ' + item.lastName).toLowerCase().includes(searchText.toLowerCase())
      );
    // Calculate the range of items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredList.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    // Calculate the total number of pages dynamically
    const getTotalPages = () =>
      Math.ceil(filteredList.length / itemsPerPage);
    return (
      <>
        <div className="container body">
          <div className='main_container'>
            <div className='col-md-3 left_col'>
              <Navbar username={localStorage.username} />
            </div>
            <Header />
            <div className='right_col'>
              <div className='container2'>
                <div className='content'>
                  <h1>User's</h1>
                  <hr />
                  <div className="main-content">
                    {!DataisLoaded && (
                      <div className="loader-back">
                        <div className="loader-element">
                          <img
                            className="loader-svg"
                            src={LogoWhite}
                            alt='Jagbandhu'
                          />
                          <ReactLoading
                            type="spinningBubbles"
                            color="#FFFFFF"
                            height={300}
                            width={150}
                          />
                        </div>
                      </div>
                    )}
                    <div className="">
                      <input
                        id="user_search_box"
                        type="text"
                        onChange={this.handleSearch}
                        placeholder="Search user..."
                        value={searchText}
                      />
                      <div className="pagination user-pagination">
                        <button
                          onClick={this.handlePrevPage}
                          disabled={currentPage === 1}
                          className="users_pageination_btns"
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                        <span>{`Showing ${(currentPage - 1) * 20 + 1}- ${usersList.length > (currentPage) * 20 ? (currentPage) * 20 : usersList.length} of ${usersList.length} users (Page ${currentPage} of ${this.getTotalPages()})`} </span>
                        <button
                          onClick={this.handleNextPage}
                          disabled={currentPage === this.getTotalPages()}
                          className="users_pageination_btns"
                        >
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </div>
                      <div className="line-seperator"></div>
                      <div className="user-list">

                        {DataisLoaded && usersList && currentItems.length > 0 && currentItems.map((usersl, index) => (
                          <a
                            key={usersl.cdId}
                            data-idx={index}
                            data-cdid={usersl.cdId}
                            onClick={this.selectUser}
                            className="user_card"
                          >
                            <a
                              key={usersl.cdId}
                              data-idx={index}
                              data-cdid={usersl.cdId}
                              onClick={this.selectUser}
                              className="user_card"
                            >
                              <div className="user_image_container">
                                <img
                                  className="user_image"
                                  src={"data:image/png;base64," + usersl.image}
                                  alt="user profile image"
                                />
                              </div>
                              <div className="text-center">
                                <h5 className="user_name">{usersl.firstName + ' ' + usersl.lastName}</h5>
                                <h6 className="display_name">{usersl.displayName}</h6>
                                <h6 className="mobile">{usersl.mobile}</h6>
                              </div>
                            </a>
                          </a>
                        ))}
                        {DataisLoaded && usersList && currentItems.length === 0 && <div><p>No Users on this page</p></div>}
                        {!usersList && <div><p>No Users to show</p></div>}
                      </div>
                      <div className="line-seperator"></div>
                      <div className="pagination user-pagination">
                        <button
                          onClick={this.handlePrevPage}
                          disabled={currentPage === 1}
                          className="users_pageination_btns"
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                        <span>{`Showing ${(currentPage - 1) * 20 + 1}- ${usersList.length > (currentPage) * 20 ? (currentPage) * 20 : usersList.length} of ${usersList.length} users (Page ${currentPage} of ${this.getTotalPages()})`} </span>
                        <button
                          onClick={this.handleNextPage}
                          disabled={currentPage === this.getTotalPages()}
                          className="users_pageination_btns"
                        >
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                    {this.state.role === "Admin" &&
                      <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header>
                          <Modal.Title>User Details</Modal.Title>
                          <button
                            id="close_user_details_btn"
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={this.handleClose}>
                            <i className="fa fa-times"></i>
                          </button>
                        </Modal.Header>
                        <Modal.Body>
                          {this.state.selectedUsrIndex &&
                            <LayerDetails key={this.state.selectedUsrIndex} user={this.state.selectedUser} />}
                          {!this.state.selectedUsrIndex && <p>No User Selected</p>}
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                      </Modal>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Users;