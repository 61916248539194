import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background-color: #284672;
  height: 100vh;
  display: block;
  z-index: 12;
  content-align:center;
  transition: 0.5s;

`;

export const NavLink = styled(Link)`
  color: #fff;
  display: block;
  align-items: center;
  text-decoration: none;
  padding: 10px 1rem;
  cursor: pointer;
  text-align:ceter;
  margin:10px;
  font-size:18px;
  &.active {
    border: 0px solid;
    background-color: #c9c9c9;
    color: #000;
    border-radius: 10px;
   
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;

  }
`;

export const NavMenu = styled.div`
  display: block;
  width:100%;
  align-items: center;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
white-space: nowrap; */
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    display: block;
  }
`;