import React, { useEffect, useState } from 'react'
import axios from "axios";
import styled from 'styled-components'
import Navbar from "../../components/Navbar";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
function CustomTextFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search...`}
    />
  );
}

function CustomNumberFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      type="number"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Filter by Number`}
    />
  );
}
const Styles = styled.div`
  padding: 1rem;
  table {
  border-spacing: 0;
  border: 1px solid black;
  width:100%;
  margin:10px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th,td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    :last-child {
      border-right: 0;
    }
  }
}
`
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  return (
    <span>
      Search:{''}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}
function DefaultColumnFilter({
  // column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <div></div>
  )
}
function Table({ columns, data }) {
  const filterTypes = (
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    showForm,
    // setPageSize,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )
  // const firstPageRows = rows.slice(0, 10);
  const firstPageRows1 = page.slice(0, 10);
  return (
    <>
      <h1>Relation Name</h1>
      <table className='data_table' {...getTableProps()}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })} */}
          {firstPageRows1.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <div id='filter_det'>
        Showing the{" "}
        {pageIndex * 10 + 1}-
        {(pageIndex + 1) * 10 > rows.length ? rows.length : (pageIndex + 1) * 10} results of {rows.length} rows
      </div>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"First"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Last"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const rows = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(rows);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
      </div>
      {/*  */}
    </>
  )
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = val => typeof val !== 'number'
function App() {
  const updateData = () => {
    const editedRelationName = document.getElementById('edit_rel_name').value;
    const editedRelId = document.getElementById('edit_rel_id').value;

    axios.post(`${globaladdress}updaterelname?relName=${editedRelationName}&relId=${editedRelId}&key=${globalkey}&token= ${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
      .then((response) => {
        console.log('Data updated successfully');
        // if (response.data === "Relation Name edited Successfully!") {
        if (response.data === "Session timeOut!") {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (response.data === "Relation Name edited Successfully!") {
          // Reload the page if the response is as expected
          window.location.reload();
        } else {
          toast.error("Something is strange (Relation Name not updated)!")
        }
        closeEditModal();
      })
      .catch((error) => {
        console.log("Update not went as smooth as expected!")
      });
  };
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const openEditModal = (row) => {
    setSelectedRow(row);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setSelectedRow(null);
    setEditModalOpen(false);
  };
  const populateEditForm = () => {
    if (!selectedRow) return null;
    const relationName = selectedRow.original.relationName;
    const relId = selectedRow.original.relId;
    return (
      <>
        <div className='edit_relation_lkp_form'>
          <label>Relation Id :&ensp;&nbsp;&emsp;&nbsp;</label>
          <input
            id="edit_rel_id"
            type="none"
            defaultValue={relId}
            disabled
          />
          <br></br>
          <hr></hr>
          <label>Relation Name: &nbsp;</label>
          <input
            id="edit_rel_name"
            type="text"
            defaultValue={relationName}
          />
          <br /><br />
        </div>
      </>
    );
  };
  function showForm() {
    document.querySelector(".addrelform").style.display = "flex";
    document.querySelector('#showform').style.display = "none";
  }
  function closeForm() {
    document.querySelector("#closeform").style.display = "none";
    document.querySelector('#showform').style.display = "flex";
  }
  function add() {
    var relation_name = document.getElementById("rel_name").value
    var relation_id = document.getElementById("rel_id").value
    if (relation_name && relation_id) {
      // toast.warning("passed condition")
      // toast.warning("relation name: "+relation_name);
      // toast.warning("relation Id: "+relation_id);
      try {
        axios.post(globaladdress + "addrelationlkp?relationName=" + relation_name + "&relId=" + relation_id + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
          .then((response) => {
            if (response.data === "Session timeOut!") {
              window.localStorage.clear();
              window.location.href = "/"
            } else {
              window.location.reload()
            }
          })
        //  toast.warning("function called in try bloack");
      } catch {
        toast.error("api call skipped!!")
      }
    } else {
      toast.warning("Please fill the required feilds!")
    }
  }
  function rolecheck() {
    var role_t = window.localStorage.getItem("role");
    console.log(role_t)
    if (role_t === "User") {
      document.querySelector(".addrelbtn").style.display = "none";
    }
  }

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [showModal, setModal] = React.useState(false);
  function handleShow() { setModal(true) }
  function handleClose() { setModal(false) }
  useEffect(() => {
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getrelationlkp?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          setData(res)
        }
      }).catch((error) => {
        console.error("Fetch error: ", error);
      })
    fetch(globaladdress + "getunnamedrelations?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          setData2(res)

        }
      }).catch((error) => {
        console.error("Fetch error: ", error);
      })
    rolecheck();
  }, [])
  const columns = [{
    Header: 'Relations',
    columns: [
      {
        Header: 'Sl No.',
        accessor: 'rtId',
        Footer: 'Sl No.',
      },
      {
        Header: 'Relation Name',
        accessor: 'relationName',
        Footer: 'Relation Name',
        Filter: CustomTextFilter,
      },
      {
        Header: 'Relation Id',
        accessor: 'relId',
        Footer: 'Relation Id',
        Filter: CustomNumberFilter,
      },
      {
        Header: 'Edit',
        accessor: 'editrel',
        Footer: 'Edit',
        Cell: ({ row }) => (
          <button
            id='edit_relation_lkp_btn'
            onClick={() => openEditModal(row)}>
            Edit
          </button>
        ),
      },
    ],
  },
  ]
  const columns2 = [{
    Header: 'Relation Codes',
    columns: [
      {
        Header: 'Code.',
        accessor: 'code',
        Footer: 'Code.',
      },
    ],
  },
  ]
  return (
    <div className="container body">
      <div className='main_container'>
        <div className='col-md-3 left_col'>
          <Navbar username={localStorage.username} />
        </div>
        <Header />
        <div className='right_col'>
          <div className='container2'>
            <div className='content'>
              <div className="addrelbtn">
                <button
                  className='showform_btn'
                  id="showform"
                  type="submit"
                  onClick={handleShow}>
                  Add Relation
                </button>
              </div>
              <Styles>
                <div className='col-md-7'>
                  <Table
                    columns={columns}
                    data={data}
                  />
                </div>
                <div className='col-md-5'>
                  <h3>Relation codes without names</h3>
                  <table>
                    <tbody>
                      {data2 && data2.map((relcode, index) => {
                        return <tr key={index}><td >{relcode.customer_rel}
                        </td>
                        </tr>;
                      })}
                    </tbody>
                  </table>
                </div>
              </Styles>
            </div>
            <Modal
              show={showModal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title
                  id='modal_heading'>
                  Add Relation
                </Modal.Title>
                <br></br>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleClose}>
                  <i className="fa fa-times"></i>
                </button>
              </Modal.Header>
              <Modal.Body>
                <form >
                  <div className="addrelation_form">
                    <label>Relation Name: &nbsp; </label>
                    <input
                      id="rel_name"
                      type="text"
                      defaultValue=""
                    />
                    <br /><br />
                    <label> &ensp;&nbsp;&emsp;Relation Id: &nbsp; </label>
                    <input
                      id="rel_id"
                      type="number"
                      defaultValue=""
                    />
                  </div>
                  <br />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={add}>
                  Add New Relation
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={isEditModalOpen}
              onHide={closeEditModal}>
              <div className='edit_rel_lkp_model_container'>
                <Modal.Header>
                  <Modal.Title>Edit Relation</Modal.Title>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeEditModal}>
                    <i className="fa fa-times"></i>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    {populateEditForm()}
                  </form>
                </Modal.Body>
                <div className='edit_rel_lkp_model_footer' >
                  <Modal.Footer>
                    <div className='edit_rel_lkp_model_btn'>
                      <Button
                        id='rel_lkp_update_btn'
                        variant="primary"
                        onClick={updateData}>
                        Update
                      </Button>
                    </div>
                  </Modal.Footer>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
export default App