import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class PopupForm extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) {
            window.location.replace("/");
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'Bug Fix',
            selectedCategory: 'Family',
            option: '',
            category: '',
            showPopup: false,
            showedit_app_notes: false,
            title: '',
            type: '',
            message: '',
            app_notes: [],
            id: '',
        };
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.categorychange = this.categorychange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // update form
        this.updateappnotes = this.updateappnotes.bind(this);
        this.optionChange = this.optionChange.bind(this);
    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getappnotes?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        app_notes: res,
                    });
                }
            }).catch((error) => {
                console.error('Fetch error', error);
            });
    }
    togglePopup = () => {
        var role = window.localStorage.getItem('role');
        if (role !== 'Admin') {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showPopup: !this.state.showPopup });
        }
    };
    toggleedit_app_notes = (e) => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({
                title: e.currentTarget.dataset.title,
                id: e.currentTarget.dataset.nid,
                message: e.currentTarget.dataset.msg,
                selectedOption: e.currentTarget.dataset.selopt
            });
            // toast.warning(this.state.selectedOption);
            this.setState({ showedit_app_notes: !this.state.showedit_app_notes });
        }
    };
    handleOptionChange(event) {
        var selopt = event.currentTarget.value;
        this.setState({ selectedOption: selopt });
    }
    categorychange(event) {
        var selcat = event.currentTarget.value;
        this.setState({ selectedCategory: selcat });
    }
    optionChange(event) {
        var option1 = event.currentTarget.value;
        this.setState({ selectedOption: option1 });
    }
    handleSubmit(event) {
        event.preventDefault();
        var title = document.getElementById('note_title').value;
        var msg = document.getElementById('note_message').value;
        var type = this.state.option;
        alert(type);
        try {
            axios.post(globaladdress + "addreleasenotes?title=" + title + "&message=" + msg + "&type=" + type + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.text();
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload()

                    }
                });
            //  alert("function called in try bloack");
        } catch {
            toast.warning("api call skipped!!")
        }
    }
    updateappnotes(event) {
        event.preventDefault();
        var dtset = {
            id: this.state.id,
            title: document.getElementById('edit_note_title').value,
            msg: document.getElementById('edit_note_message').value,
            type: this.state.selectedOption,
        }
        toast.info(JSON.stringify(dtset));
        try {
            axios.post(globaladdress + "updatereleasenotes?id=" + dtset.id + "&title=" + dtset.title + "&message=" + dtset.msg + "&type=" + dtset.type + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.text();
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload()

                    }
                })
            //  alert("function called in try bloack");
        } catch {
            toast.error("api call skipped!!")
        }
    }
    render() {
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    <Header />
                    <div className='right_col'>
                        <div className='table_container'>
                            <div>
                                <button
                                    id="add_release_notes_btn"
                                    onClick={this.togglePopup}>
                                    Add Release Note
                                </button>
                                {this.state.showPopup && (
                                    <div className="popup">
                                        <div className="popup-inner">
                                            <h2>Add Release Note</h2>
                                            <br></br>
                                            <form onSubmit={this.handleSubmit}>
                                                <label>
                                                    Choose type of Release:
                                                    <select
                                                        defaultValue={this.state.selectedOption}
                                                        onChange={this.handleOptionChange}>
                                                        <option value="Bug Fix">Bug Fix</option>
                                                        <option value="New Release">New Release</option>
                                                        <option value="Maintenance">Maintenance Note</option>
                                                    </select>
                                                </label>
                                                <br />
                                                <label>
                                                    Title:
                                                    <input
                                                        id='note_title'
                                                        type="text"
                                                    />
                                                </label>
                                                <br />
                                                <label>
                                                    Message:
                                                    <textarea
                                                        id='note_message'
                                                    />
                                                </label>
                                                <br />
                                                <div className='addreleasenotespopupform'>
                                                    <button type="submit">Send</button>
                                                    <button
                                                        className="close-btn"
                                                        style={{ float: 'right' }}
                                                        onClick={this.togglePopup}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='release_notes_tab'>
                                <table
                                    id='releasenotes_table'
                                    key={this.state.app_notes.id}>
                                    <tr>
                                        <th>Title</th>
                                        <th>Message</th>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>status</th>
                                        <th>Edit</th>
                                    </tr>
                                    {this.state.app_notes && this.state.app_notes.map((an) => (
                                        <tr>
                                            <td>{an.title}</td>
                                            <td>{an.description}</td>
                                            <td>{an.date}</td>
                                            <td>{an.type}</td>
                                            <td>{an.status}</td>
                                            <td>
                                                <button
                                                    id='edit_release_notes'
                                                    data-nid={an.id}
                                                    data-title={an.title}
                                                    data-msg={an.description}
                                                    data-selopt={an.type}
                                                    type="submit"
                                                    onClick={this.toggleedit_app_notes}>
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                                {this.state.showedit_app_notes && (
                                    <div className="edit_app_notes">
                                        <div className="edit_app_notes-inner">
                                            <h2>Update Release Note</h2>
                                            <br></br>
                                            <form onSubmit={this.updateappnotes}>
                                                <label>
                                                    Choose type of Release:
                                                    <select
                                                        defaultValue={this.state.selectedOption}
                                                        onChange={this.optionChange}>
                                                        <option value="Bug Fix">Bug Fix</option>
                                                        <option value="New Release">New Release</option>
                                                        <option value="Maintenance">Maintenance Note</option>
                                                    </select>
                                                </label>
                                                <br />
                                                <label>
                                                    Title:
                                                    <input
                                                        id='edit_note_title'
                                                        type="text"
                                                        defaultValue={this.state.title}
                                                    />
                                                </label>
                                                <br />
                                                <label>
                                                    Message:
                                                    <textarea
                                                        id='edit_note_message'
                                                        defaultValue={this.state.message}
                                                    />
                                                </label>
                                                <br />
                                                <div className='editreleasenotespopupform'>
                                                    <button type="submit">Send</button>
                                                    <button
                                                        className="close-btn"
                                                        data-nid=''
                                                        data-title=''
                                                        data-msg=''
                                                        data-selopt='Bug Fix'
                                                        style={{ float: 'right', padding: '10px' }}
                                                        onClick={this.toggleedit_app_notes}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PopupForm;