import React from "react";
import Navbar from "../../components/Navbar";
import { globaladdress, globalkey, s_key, s_secret } from "../variables";
// import axios from "axios";
// import { FaCheck, FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Transactionns extends React.Component {
  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      DataisLoaded: false
    };
  }


  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    //  fetch(globaladdress+"gettransactiondetails?key="+globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
    // .then((res) => res.json())
    // .then((json) => {
    //     this.setState({
    //         items: json,
    //         DataisLoaded: true 
    //     });
    // })
  }
  accept(e) {
    // var pid=e.target.getAttribute('data-pid');
    // var status= 1
    try {
      // axios.post("http://192.168.1.8:3003/updateproviderstatus?pid="+pid+"&status="+status+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(()=>{window.location.reload()});
    } catch {
      toast.error("api call skipped!!")
    }
  }
  reject(e) {
    // var pid=e.target.getAttribute('data-pid');
    // var status= 2
    try {
      // alert("myfunction called wwith pid:"+pid);
      // axios.post("http://192.168.1.8:3003/updateproviderstatus?pid="+pid+"&status="+status+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(()=>{window.location.reload()});
    } catch {
      toast.error("api call skipped!!");
    }

  }
  render() {
    const { DataisLoaded, items } = this.state;
    const luId = window.localStorage.getItem("userid");
    const lufn = window.localStorage.getItem("firstname");
    const luln = window.localStorage.getItem("lastname")
    const luun = window.localStorage.getItem("username")
    const lue = window.localStorage.getItem("email")
    if (items.length === 0) return <div className='full_page'>
      <div className="jb_header">
        <div className="jb_header_content">
          <h1>Yeah! header here</h1>
        </div>
      </div>
      <div className='col-md-2 side-nav'>

        <Navbar />
      </div>
      <div className='col-md-10 page-content'>
        <div className='container2'>
          <div className='content'>

            <h1> Transaction will appear here.... </h1> </div>
        </div>
      </div></div>;
    // if(items === "authentication failed request cannot be processed") return <div className="container1">
    //   <div className='landingpage'>
    //     <h1>unauthorized request!</h1>
    //   </div>
    // </div>;
    if (!DataisLoaded) return
    <div className='full_page'>
      <div className='col-md-2 side-nav'>
        <Navbar />
      </div>
      <div className='col-md-10 page-content'>
        <div className='container2'>
          <div className='content'>
            <h1> Pleses wait some time.... </h1> </div>
        </div>
      </div></div>;
    return (

      <div className='full_page'>
        <div className='col-md-2 side-nav'>
          <Navbar />
        </div>
        <div className='col-md-10 page-content'>
          <div className='container2'>
            <div className='content'>
              <h1>Providers Details</h1>
              <table className="react_table" key={items[0].pId} >
                <tr>
                  {/* trans_Id, cId, pId, transaction_id, amount, trans_date, time, inv_id, status */}
                  <th>Customer Id</th>
                  <th>Provider Id</th>
                  <th>Transaction Id</th>
                  <th>Amount</th>
                  <th>Transaction Date</th>
                  <th>Time</th>
                  <th>Invoice Id</th>
                  <th>Status</th>
                </tr>
                {items.map((item) => (
                  <tr >
                    <td>{item.cId}</td>
                    <td>{item.pId}</td>
                    <td>{item.transaction_id}</td>
                    <td>{item.amount}</td>
                    <td>{item.trans_date}</td>
                    <td>{item.time}</td>
                    <td>{item.inv_id}</td>
                    <td>{item.status}</td>
                  </tr>

                ))
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Transactionns;